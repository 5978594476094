import React, { useState, useRef, useEffect } from 'react';

interface DropdownProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  buttonClass?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ children, title = 'Toggle Dropdown', className, buttonClass }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`relative inline-block w-full h-[38px] bg-white rounded-[15px] border border-[#E2E8F0] leading-[18px] ${className || ''}`}>
      <button onClick={() => setIsOpen(!isOpen)} className={`flex items-center justify-between w-full h-full px-4 cursor-pointer ${buttonClass || ''}`}>
        <span>{title}</span>
        <div
          className={`w-[9px] h-[9px] border-r-2 border-b-2 border-gray-600 transform transition-transform duration-300 ${isOpen ? '-rotate-[135deg]' : 'rotate-45'}`}
          style={{ transformOrigin: 'center' }}
        />
      </button>
      <div className={`absolute top-full left-0 z-[1000] ${isOpen ? '' : 'hidden'} w-full bg-white p-4 pr-1 mt-1 rounded-[15px] border border-[#E2E8F0]`}>
        <div className="max-h-[300px] overflow-auto text-[#666] text-[14px] leading-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
