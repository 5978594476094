import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import { getChatRoomMessages } from "../../../api/services/messages/requests";

import { IChatProperty, IMessage, IMessages, IParticipant } from "../types/chat";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import useWebsocketChatStore from "../stores/useWebsocketChatStore";

import { InputUncontrolled } from "../../../common/components/Input/Input";

import ChatSendArrow from "../../../common/assets/icons/ChatSendArrow";

import ChatRoomHeader from "./ChatRoomHeader";
import ChatProperty from "./ChatProperty";
import MessagesList from "./MessagesList";

interface ChatRoomProps {
  currentRoomOpponent: IParticipant;
  currentRoomProperty?: IChatProperty;
  currentRoomCreatedAt?: string;
  sendMessage: (message: IMessage | string) => void;
  handleMessageRead: (messageId: string) => void;
}

const ChatRoom: React.FC<ChatRoomProps> = ({ currentRoomOpponent, currentRoomProperty, currentRoomCreatedAt = '0', sendMessage, handleMessageRead }) => {
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();

  const { roomId, message, setMessage, messages, loadMoreMessages, page, setPage, hasMore, setHasMore } = useWebsocketChatStore()

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const startDate = new Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(currentRoomCreatedAt))

  const { data, isFetching } = useQuery(["roomId", roomId, page], async () => {
    setLoader(true);
    const data: IMessages = await getChatRoomMessages(roomId, page);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    },
    enabled: !!roomId,
    keepPreviousData: true,
  });

  const handleChange = (event: any) => {
    setMessage(event.target.value)
  }

  const handleSend = () => {
    sendMessage(message)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleScroll = () => {
    if (scrollRef.current && initialLoadDone && scrollRef.current?.scrollTop <= 0 && hasMore && !isFetching) {
      setTimeout(() => {
        setPage((prevPage) => prevPage + 1);
      }, 300)
    }
  };

  useEffect(() => {
    if (scrollRef.current && messages.length > 0) {
      if (initialLoadDone) {
        scrollRef.current.scrollTop += 520;
      } else {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        setTimeout(() => {
          setInitialLoadDone(true);
        }, 300)
      }

    }
  }, [messages, initialLoadDone]);

  useEffect(() => {
    if (data?.items && data.items.length > 0) {
      const { total, page, limit, items } = data;
      const hasMore = page * limit < total;

      loadMoreMessages(items, hasMore);
      setHasMore(hasMore)
    }
  }, [data]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  return (
    <div className="grid grid-rows-[auto_1fr_auto] bg-white p-[20px_15px_34px_20px] rounded-r-[15px] h-[calc(100vh-124px)] min-h-[538px]">
      <ChatRoomHeader currentRoomOpponent={currentRoomOpponent} />
      <div
        ref={scrollRef}
        className="h-[calc(100vh-284px)] min-h-[370px] overflow-y-auto pr-4 mb-2"
        onScroll={handleScroll}
      >
        <div className="grid gap-[15px] mb-[15px]">
          <div className="text-[14px] text-[#AAAAAA80] text-center">{startDate}</div>
          {!!currentRoomProperty && (
            <ChatProperty currentRoomProperty={currentRoomProperty} />
          )}
          <MessagesList handleMessageRead={handleMessageRead} />
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="relative h-[50px]">
        <InputUncontrolled className="pr-[50px]" value={message} onChange={handleChange} onKeyDown={handleKeyDown}/>
        <button className="absolute top-1/2 right-[5px] transform -translate-y-1/2 p-[15px]" onClick={handleSend}>
          <ChatSendArrow />
        </button>
      </div>
    </div>
  )
}

export default ChatRoom;
