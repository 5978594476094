import {create} from "zustand";
import {User} from "../types/dto/user";
import Cookies from 'universal-cookie';
import {COOKIE_ACCESS_TOKEN} from "../constants/cookies";


interface IAuthStore {
  accessToken?: string | null;
  setAccessToken: (accessToken?: string | null) => void;
  user?: User | null;
  setUser: (user?: User | null) => void;
  logout: () => void;
}

const cookies = new Cookies();
const useAuthStore = create<IAuthStore>((set) => ({
  accessToken: cookies.get(COOKIE_ACCESS_TOKEN) || null,
  setAccessToken: (accessToken) => {
    set((prevState) => ({
      ...prevState,
      accessToken
    }));
    cookies.set(COOKIE_ACCESS_TOKEN, accessToken);
  },
  user: null,
  setUser: (user) => set((prevState) => ({
    ...prevState,
    user
  })),
  logout: () => {
    set((prevState) => ({
      ...prevState,
      accessToken: null,
      user: null,
    }));

    cookies.remove(COOKIE_ACCESS_TOKEN);
  },
}));


export default useAuthStore;
