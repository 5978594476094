import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translateCommonCZ from '../src/locales/cz/common.json'
import translateCommonEN from '../src/locales/en/common.json'

const resources = {
  cz: {
    translation: translateCommonCZ
  },
  en: {
    translation: translateCommonEN
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'cz',
    fallbackLng: 'cz',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  }).then(() => console.log("i18n success init"));


export default i18n;
