import {Link} from "react-router-dom";
import Button from "../../../common/components/Button/Button";
import React from "react";

const RegistrationBlock = () => {
  return (
    <div
      className="w-[676px] rounded-[20px] flex flex-col justify-between items-center h-full pt-[108px] pb-[82px] text-center text-white bg-registration-gradient-block bg-cover"
    >
      <div>
        <div className="text-[40px] font-bold">Welcome to Reelty</div>
        <div className="text-[22px] mt-3">Your Command Center for Real Estate Management.</div>
      </div>
      <div className="w-full px-[84px]">
        <div className="text-[40px] font-bold">Want an Agent Account?</div>
        <div className="text-[20px] mt-5">
          Sign up now and unlock a world of benefits! Seamlessly manage properties, track clients, and enjoy many more
          perks. Don’t miss out on the full potential of Reelty.
        </div>
        <div className="px-[54px] mt-[60px]">
          <Link to="/create-account">
            <Button color="white" size="large" fullWidth={true}>
              Register Now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegistrationBlock;
