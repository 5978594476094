import React, { useEffect } from "react";
import { useQuery } from "react-query";

import { getChatRooms } from "../../api/services/messages/requests";

import { IChat, IChatList, IParticipant } from "../../features/messages/types/chat";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../common/stores/useAuthStore";

import useWebsocketChatStore from "../../features/messages/stores/useWebsocketChatStore";

import useWebSocketChat from '../../features/messages/hooks/useWebsocketChat';

import ChatsList from "../../features/messages/components/ChatsList";
import ChatRoom from "../../features/messages/components/ChatRoom";

const MessagesPage = () => {
  const { loader, setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { user } = useAuthStore()

  const { roomId, setRoomId, resetStore } = useWebsocketChatStore()

  const { connect, sendMessage, handleMessageRead } = useWebSocketChat();

  const { data = [] } = useQuery(["chatRooms", roomId], async () => {
    setLoader(true);
    const data: IChatList = await getChatRooms();
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const chatListData = data.map((chat: IChat) => {
    const { id, unreadMessages, property, participants, isSupport } = chat;
    const opponent = participants.find(participant => participant.id !== user?.id) as IParticipant;
    const name = opponent?.name;
    const avatar = opponent?.avatar;

    return {
      id,
      name,
      avatar,
      address: property?.address,
      unreadMessages,
      isSupport,
    }
  })

  const currentRoom = data.find((dataItem: IChat) => dataItem.id === roomId);
  const currentRoomOpponent = currentRoom?.participants.find(participant => participant.id !== user?.id) as IParticipant;
  const currentRoomProperty = currentRoom?.property
  const currentRoomCreatedAt = currentRoom?.createdAt

  useEffect(() => {
    connect()
  }, [])

  useEffect(() => {
    if(!roomId && data.length) {
      setRoomId(data[0].id);
    }
  }, [roomId, data])

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [])

  return (
    <div className="p-[18px_25px_26px_25px] h-[calc(100vh-80px)]">
      {chatListData.length ? (<div
        className="h-full grid grid-cols-[auto_1fr] gap-[2px] overflow-x-auto bg-transparent p-0 h-[calc(100vh-124px)] min-h-[538px]">
        <ChatsList chatListData={chatListData}/>
        <ChatRoom currentRoomOpponent={currentRoomOpponent} currentRoomProperty={currentRoomProperty}
                  currentRoomCreatedAt={currentRoomCreatedAt} sendMessage={sendMessage}
                  handleMessageRead={handleMessageRead}/>
      </div>) : !loader && (
        <div className="flex-center w-full h-full">No chat rooms available</div>
      )}
  </div>
  )
}

export default MessagesPage;
