import axiosInstance from "../../axios";
import {AxiosResponse} from "axios";
import {IResponse} from "../../../common/types/response";
import {IPostMediaUploadImage} from "./types";
import {Image} from "../../../common/types/image";


export const postMediaUploadImage = async (payload: IPostMediaUploadImage) => {
  const formData = new FormData();
  formData.append("image", payload.image);
  const response: AxiosResponse<IResponse<Image>> = await axiosInstance.post("/media/upload/image", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data.data;
}
