import { useEffect } from "react";
import { useQuery } from "react-query";

import { getUserMe } from "../../api/services/user/requests";

import useAuthStore from "../stores/useAuthStore";
import useGlobalLoaderStore from "../stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../stores/useGlobalErrorStore";

const useGetUserMe = () => {
  const { accessToken, setUser } = useAuthStore((state) => state);
  const setLoader = useGlobalLoaderStore((state) => state.setLoader);
  const { setError } = useGlobalErrorStore();

  const { data: user } = useQuery(["user_me", accessToken], async () => {
    setLoader(true);
    const data = await getUserMe();
    setLoader(false);
    return data;
  }, {
    enabled: !!accessToken,
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  useEffect(() => {
    setUser(user);
  }, [user]);
}

export default useGetUserMe;
