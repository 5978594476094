import React from "react";

import { getAddressString } from "../../../common/utils/address";

import { IChatProperty } from "../types/chat";

import PropertyStatus from "../../property/components/PropertyStatus/PropertyStatus";

import LocationIcon from "../../../common/assets/icons/LocationIcon";
import NoImageIcon from "../../../common/assets/icons/NoImageIcon";

interface ChatPropertyProps {
  currentRoomProperty: IChatProperty;
}

const ChatProperty: React.FC<ChatPropertyProps> = ({ currentRoomProperty }) => {
  return (
    <div className="relative grid gap-1 w-[329px]">
      {currentRoomProperty?.status && <div className="absolute top-[10px] right-[10px]">
        <PropertyStatus status={currentRoomProperty.status}/>
      </div>}
      {currentRoomProperty?.mainImage?.url ? (
        <img className="block w-full h-[186px] bg-[#ccc] rounded-[10px]" src={currentRoomProperty.mainImage.url}
             alt="property photo"/>
      ) : <div className="flex-center w-full h-[186px] bg-[#f8f8f8] rounded-[10px]"><NoImageIcon width={40} height={40} /></div>}
      <div className="font-Manrope text-[12px] text-[#666666]">1000 15 25 11</div>
      <div className="grid grid-cols-[1fr_auto] items-center gap-2 font-Manrope">
        <div className="flex items-center gap-[6px]">
          <LocationIcon width={18} height={18}/>
          <div className="text-[14px] text-[#333333] whitespace-nowrap truncate">
            {currentRoomProperty?.address && getAddressString(currentRoomProperty?.address) || 'Unknown'}
          </div>
        </div>
        <div>
          <div
            className="flex-center whitespace-nowrap h-[30px] bg-[#006CFB0D] text-[12px] font-semibold text-[#333333] px-2">
            {!!currentRoomProperty?.price ? `Kč ${currentRoomProperty.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}` : 0}
            <span>{currentRoomProperty?.priceType === 'perMonth' ? '/mo' : currentRoomProperty?.priceType === 'perDay' ? '/day' : ''}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatProperty;
