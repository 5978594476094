import { FC } from "react";

import { ISvgProps } from "../../types/svg";

const RIcon: FC<ISvgProps> = ({height, width, color, background, opacity}) => {

  return (
    <svg
      width={width || 21}
      height={height || 20}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || "#006CFB"}
      opacity={opacity || 1}
    >
      <g>
        <circle cx="10.5555" cy="10" r="10" fill={background || "white"} />
        <path
          d="M5.00085 4.89278V13.0436L7.43227 10.6266C7.56684 10.4981 7.79614 10.5018 7.9254 10.6348L13.222 15.8576H15.9084C16.0834 15.8576 16.1767 15.6729 16.0604 15.5554L12.1738 11.677C12.1782 11.668 12.1788 11.667 12.1833 11.658H12.337C13.2536 11.658 14.5288 11.275 15.1293 10.6559C15.7084 10.0588 16.0743 9.24287 16.0997 8.33848C16.1546 6.40115 14.6085 4.81543 12.0395 4.81543L5.07227 4.81886C5.03273 4.81913 5.00085 4.85212 5.00085 4.89278ZM12.8738 9.07023C12.6003 9.24736 12.2225 9.34081 11.8052 9.34081H7.75335C7.71204 9.34081 7.67869 9.30623 7.67869 9.26373V7.17381C7.67869 7.13131 7.71204 7.09673 7.75335 7.09673H11.8049C12.6395 7.09673 13.3165 7.49666 13.3165 8.16426C13.3168 8.49793 13.1477 8.8931 12.8738 9.07023ZM10.345 14.792V15.8529C10.345 15.8935 10.3131 15.9265 10.2736 15.9265H5.08378C5.04424 15.9265 5.01236 15.8935 5.01236 15.8529V14.792C5.01236 14.7722 5.02033 14.7529 5.0342 14.7392L7.62911 12.1493C7.65685 12.1216 7.70082 12.1216 7.72827 12.1493L10.3232 14.7392C10.337 14.7529 10.345 14.7722 10.345 14.792Z"
        />
      </g>
    </svg>
  );
}

export default RIcon;
