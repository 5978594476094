import React from "react";

import { deleteChatRoom } from "../../../api/services/messages/requests";

import { IParticipant } from "../types/chat";

import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import useWebsocketChatStore from "../stores/useWebsocketChatStore";

import BurgerDropdown from "../../../common/components/BurgerDropdown/BurgerDropdown";

import MenuIcon from "../../../common/assets/icons/MenuIcon";
import ThrashCan from "../../../common/assets/icons/ThrashCan";

interface ChatRoomHeaderProps {
  currentRoomOpponent: IParticipant;
}

const ChatRoomHeader: React.FC<ChatRoomHeaderProps> = ({ currentRoomOpponent }) => {
  const { setError } = useGlobalErrorStore();

  const { roomId } = useWebsocketChatStore()

  const handleChatDelete = async () => {
    try {
      const data = await deleteChatRoom(roomId)
      console.log('chatDeleteResponse', data);
    } catch (error: any) {
      setError(error.response.data.message);
    }
  }

  return (
    <div className="flex justify-between items-center gap-2 mb-5">
      <div className="flex items-center gap-[10px] text-[14px] text-[#2D3748] font-bold">
        <div>
          {currentRoomOpponent?.avatar ? (
            <img
              src={currentRoomOpponent.avatar}
              width={36}
              height={36}
              alt="user icon"
              className="h-[36px] w-[36px] rounded-[12px] object-cover"
            />
          ) : (
            <div className="flex-center rounded-[12px] w-[36px] h-[36px] bg-blue text-white">{currentRoomOpponent?.name?.charAt(0)}</div>
          )}
        </div>
        <div>{currentRoomOpponent?.name}</div>
      </div>
      <BurgerDropdown title={<MenuIcon />} className="w-[18px] h-[18px] mr-[10px] cursor-pointer">
        <button onClick={handleChatDelete} className="flex items-center gap-[10px] bg-white p-[10px] rounded-[10px] shadow-[0_4px_10px_0_#0000000D]">
          <div>
            <ThrashCan />
          </div>
          <span className="text-[14px] whitespace-nowrap">Delete chat</span>
        </button>
      </BurgerDropdown>
    </div>
  )
}

export default ChatRoomHeader;
