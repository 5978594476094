import {create} from "zustand";

type PropertyEditStoreStepType = "disposition" | "parameters" | "photosAndDescription" | "features" | "priceAndRentalTerms" | "preview";

interface IPropertyEditStore {
  step: PropertyEditStoreStepType;
  setStep: (newStep: PropertyEditStoreStepType) => void;
  init: () => void;
}

const usePropertyEditStore = create<IPropertyEditStore>((set) => ({
  step: "disposition",
  setStep: (newStep) => set((prevState) => ({
    ...prevState,
    step: newStep,
  })),
  init: () => set({
    step: "disposition",
  })
}));

export default usePropertyEditStore;
