import PinInput from "../../../common/components/PinInput/PinInput";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {postOtpEmailConfirmSend, postOtpPasswordResetSend} from "../../../api/services/otp/requests";
import useFormVerifyEmailPin from "../hooks/useFormVerifyEmailPin";

interface IVerifyEmailPinFormProps {
  email: string
}

const VerifyEmailPinForm = ({email} : IVerifyEmailPinFormProps) => {
  const {control} = useFormVerifyEmailPin(email);

  const [counter, setCounter] = useState(60);

  const startCounter = () => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1)
    }, 1000);
  }

  useEffect(() => {
    startCounter();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1)
    }, 1000);
  }, []);


  const resendCode = async () => {
    if(counter > 0 || !email) return;
    setCounter(60);
    try {
      await postOtpEmailConfirmSend({email});
      startCounter();
    }
    catch (error){
      alert(error)
    }
  };

  return (
    <div>
      <PinInput
        length={4}
        defaultValue={""}
        name="pin"
        control={control}
      />
      <div className={classNames(
        "text-[15px] text-[#666] text-center leading-[140%] mt-[32px]", {
          "cursor-pointer": counter <= 0,
        })} onClick={resendCode}>
        Resend code {counter >= 0 && counter}
      </div>
    </div>
  )
}

export default VerifyEmailPinForm;
