import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";
import React, {useRef} from "react";
import Slider from "react-slick";
import PropertyCard from "../../../common/components/PropertyCard/PropertyCard";

const DashboardLastAddedBlock = () => {
  let sliderRef = useRef<Slider | null>(null);
  const next = () => {
    sliderRef.current?.slickNext();
  };
  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const data = [
    "a1",
    "a2",
    "a3",
    "a4",
    "a5",
    "a6",
    "a7",
    "a8",
    "a9",
    "a10",
    "a11",
    "a12",
    "a13",
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 2,
  };

  return (
    <CardWrapper>
      <div className="flex justify-between items-center">
        <div className="text-[#11142d] text-[18px] font-semibold">Last Added</div>
        <div className="flex items-center gap-[8px]">
          <div className="cursor-pointer" onClick={previous}>prev</div>
          <div className="cursor-pointer" onClick={next}>next</div>
        </div>
      </div>
      <div className="mt-[20px]">
        <Slider
          ref={(slider) => {
            sliderRef.current = slider;
          }}
          {...sliderSettings}
        >
          {data.map((property, index) => (
            <div key={index}>
              <div className="flex-center mx-[16px] my-[6px]">
                <PropertyCard />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </CardWrapper>
  );
}

export default DashboardLastAddedBlock;
