import React, { FC, useState } from "react";
import { Control, Controller } from "react-hook-form";

interface IPropertyDescriptionInputProps {
  control: Control;
  name: string;
  label?: string;
}

const PropertyDescriptionInput: FC<IPropertyDescriptionInputProps> = ({ control, name, label }) => {
  const [language, setLanguage] = useState<"en" | "cz">("cz");

  const getValue = (value: any[], language: string) => {
    return value?.find((v) => v.language === language)?.value ?? "";
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <div className='relative flex flex-col'>
          <div className="flex flex-row-reverse justify-between items-center mb-1.5">
            <div
              className="cursor-pointer text-sm text-[#333])"
              onClick={() => setLanguage(language === "cz" ? "en" : "cz")}
            >
              {language}
            </div>
            {label && (
              <label htmlFor={name} className="text-sm text-[#333]">
                {label}
              </label>
            )}
          </div>
          <textarea
            value={getValue(field.value, language)}
            name={name}
            className="bg-[#f5f5f5] rounded-[8px] px-[16px] pt-[16px] pb-[12px] outline-none text-[14px] h-[162px] resize-none font-Gilroy"
            onChange={(event) => {
              const value = field.value;
              if(!value) return;
              value.find((v: any) => v.language === language).value = event.currentTarget.value;
              field.onChange(value);
            }}
          />
        </div>
      )}
    />
  )
}

export default PropertyDescriptionInput;
