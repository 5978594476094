import {useForm} from "react-hook-form";
import {ICreateAccountBase} from "../types/form";
import useCreateAccountStore from "../stores/useCreateAccountStore";
import { zodResolver } from "@hookform/resolvers/zod"
import {createAccountBaseFormSchema} from "../validations/schema";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import {IPostAuthRegisterAgentPayload} from "../../../api/services/auth/types";
import {postAuthAgentRegister} from "../../../api/services/auth/requests";
import {postOtpEmailConfirmSend} from "../../../api/services/otp/requests";

const defaultValue = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: undefined,
  legalName: '',
  address: '',
  ic: '',
  companyPhone: '',
}

const useFormCreateAccountBase = () => {
  const {data: {base}, handleBaseStep, requestError, setRequestError} = useCreateAccountStore((state) => state);
  const {setLoader} = useGlobalLoaderStore((state) => state);

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    watch,
  } = useForm<ICreateAccountBase>({
    mode: 'onBlur',
    defaultValues: base || defaultValue,
    resolver: zodResolver(createAccountBaseFormSchema),
  });

  const password = watch("password");

  const onSubmit = async (value: ICreateAccountBase) => {
    const payload: IPostAuthRegisterAgentPayload = {
      email: value.email,
      password: value.password,
      phone: value.phone,
      name: value.name,
      profile: {
        legalName: value.legalName,
        address: value.address,
        ic: value.ic,
        phone: value.companyPhone,
      },
    }
    try {
      setRequestError([]);
      setLoader(true)
      await postAuthAgentRegister(payload);
      await postOtpEmailConfirmSend({email: value.email});
      handleBaseStep(value);
    } catch (error: any) {
      const messages = error?.response?.data?.message || ["Request failed"];

      if (Array.isArray(messages)) {
        setRequestError(messages);
      } else {
        setRequestError([messages]);
      }

    } finally {
      setLoader(false)
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    password,
    isValid,
    requestError
  };
}

export default useFormCreateAccountBase;
