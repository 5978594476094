import useFormLogin from "../hooks/useFormLogin";
import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import {Link} from "react-router-dom";

const LoginForm = () => {
  const {
    control,
    handleSubmit,
    loginError
  } = useFormLogin();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-5">
        <Input
          label="Email"
          name="email"
          control={control}
          placeholder="Enter your email address"
        />
        <Input
          type="password"
          label={(
            <div className="flex justify-between items-center">
              <div>Password</div>
              <div className="text-[12px] text-[#9c9aa5] underline">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
            </div>
          )}
          name="password"
          control={control}
          placeholder="Enter password"
        />
      </div>
      <div className="mt-[54px]">
        <Button
          type="submit"
          size="large"
          fullWidth={true}
        >
          Sign In
        </Button>
      </div>
      <div className="mt-[20px]">
        {!!loginError && (
          <div className="rounded-[8px] bg-[#e53e3e1a] text-[#666666] text-[15px] p-[10px]">
            {loginError}
          </div>
        )}
      </div>
    </form>
  )
}

export default LoginForm;
