import React from 'react';
import { useNavigate } from "react-router-dom";

import useBillingModalForm from "../../hooks/useBillingModalForm";

import Select from "../../../../common/components/Select/Select";
import Button from "../../../../common/components/Button/Button";

import RIcon from "../../../../common/assets/icons/RIcon";

const options = Array.from({ length: 36 }, (_, i) => ({
  label: `${i + 1} ${i === 0 ? 'month': 'months'}`,
  value: i + 1
}));

const BillingModalForm: React.FC = () => {
  const {
    control,
    watch,
    handleSubmit,
  } = useBillingModalForm()

  const navigate = useNavigate();

  const durationValue = watch("duration");

  const cost = 1
  const amount = 13
  const notEnoughCash = amount - cost * durationValue.value < 0

  return (
    <div className="grid gap-5 w-[572px]">
      <div className="flex justify-between items-center">
        <div className="text-[#333] text-[18px] leading-5 font-bold">Publish property</div>
        <div className="flex-center gap-[10px] w-[80px] h-[35px] rounded-[10px] bg-blue text-white">{amount}<div className="w-5 h-5"><RIcon /></div></div>
      </div>
      <div>The publication of a property listing for 1 month costs 1 Recoin. The publication period will start once the listing is approved by the moderator.</div>
      <div>
        <div className="mb-[6px] text-[#333] text-[14px]">Publication duration</div>
        <Select classNames={{
          container: () => 'w-[358px] h-[50px] rounded-[15px] border border-[#E2E8F0] leading-[18px]',
          control: () => 'w-[358px] h-[50px] p-4',
          menu: () => 'bg-white p-4 pr-1 mt-1 rounded-[15px] border border-[#E2E8F0] ',
          menuList: () => 'max-h-[300px] overflow-auto text-[#666] text-[14px] leading-6',
          singleValue: () => 'text-[#666] text-[14px]'
        }} control={control} name="duration" options={options} unstyled />
      </div>
      <div className="h-[50px] flex justify-end items-end">
        <div className="flex items-center gap-[34px]">
          <div className="text-[#666666]">Amount (<span>{durationValue.label}</span>)</div>
          <div className={`flex items-center gap-[4px] ${notEnoughCash && 'text-[#E53E3E]'}`}>{cost * durationValue.value} <div className="w-5 h-5 mb-0.5"><RIcon color='white' background="#006CFB" /></div></div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        {notEnoughCash && <div className="cursor-pointer text-blue px-[18px]"
              onClick={() => navigate('/billing')}>Buy credits
        </div>}
        <Button className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" disabled={notEnoughCash} onClick={handleSubmit} >Send to moderation</Button>
      </div>
    </div>
  );
};

export default BillingModalForm;

// className="w-[358px] h-[50px] rounded-[15px]"