import { create } from "zustand";

interface IGlobalErrorStore {
  error: string | string[];
  setError: (error: string | string[]) => void;
}

const useGlobalErrorStore = create<IGlobalErrorStore>((set) => ({
  error: '',
  setError: (error) => set((prevState) => ({
    ...prevState,
    error,
  }))
}));

export default useGlobalErrorStore;
