import useForgotPasswordStore from "../stores/useForgotPasswordStore";
import {useForm} from "react-hook-form";
import {IForgotPasswordNewPassword} from "../types/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {forgotPasswordNewPasswordFormSchema} from "../validations/schema";
import {postAuthPasswordReset} from "../../../api/services/auth/requests";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

const useFormForgotPasswordNewPassword = () => {
  const {email, pin, setStep} = useForgotPasswordStore((state) => state);
  const {setLoader} = useGlobalLoaderStore((state) => state);

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    watch,
  } = useForm<IForgotPasswordNewPassword>({
    mode: "onBlur",
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(forgotPasswordNewPasswordFormSchema),
  });

  const password = watch("password");

  const onSubmit = async ({password}: IForgotPasswordNewPassword) => {
    try {
      setLoader(true);
      await postAuthPasswordReset({
        email,
        newPassword: password,
        otp: pin,
      });
      setStep("success");
    }
    catch (error: any) {
      const message = error?.response?.data?.message || "Request failed";
      alert(message);
    }
    finally {
      setLoader(false);
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
    password,
  }
}

export default useFormForgotPasswordNewPassword;
