import React, { forwardRef, InputHTMLAttributes, useState } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import classNames from "classnames";

import EyePasswordIcon from "../../assets/icons/EyePasswordIcon";
import EyeClosedPasswordIcon from "../../assets/icons/EyeClosedPasswordIcon";

import styles from "./Input.module.css";

export interface IInputProps<TFormData extends Record<string, any>>
  extends Pick<ControllerProps<TFormData>, 'control' | 'name' | 'rules' | 'defaultValue' | 'disabled'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlue?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  label?: string | React.ReactElement;
  onBlur?: (e: any) => void;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  errorText?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  classnames?: string;
  endUnit?: string;
}

function Input<TFormData extends Record<string, any>>
({
   control,
   name,
   rules,
   label,
   defaultValue,
   type,
   classnames,
   error,
   errorText,
   onChange,
   onKeyUp,
   onKeyDown,
   onPaste,
   onBlue,
   endUnit,
   ...props
 }: IInputProps<TFormData>): React.ReactElement {
  const [inputType, setInputType] = useState(type || 'text');

  const handleToggleType = () => {
    setInputType(inputType == 'password' ? 'text' : 'password');
  };

  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({field, fieldState}) => (
        <div className='relative flex flex-col'>
          {label && (
            <label htmlFor={name} className={classNames(styles['label'])}>
              {label}
            </label>
          )}
          <div className="relative">
            <input
              {...props}
              id={name}
              value={field.value}
              step={1}
              type={inputType}
              ref={field.ref}
              onWheel={type === 'number' ? numberInputOnWheelPreventChange : undefined}
              onKeyDown={(e) => {
                onKeyDown?.(e);
              }}
              onChange={(e) => {
                let value;
                if (type === "number") {
                  value = type === "number" ? e.target.valueAsNumber : e.target.value;
                } else {
                  const newValue = e.target.value;
                  const trimmedValue = newValue.replace(/^\s+/g, '');
                  value = trimmedValue.replace(/^0(?=[1-9])/, '');
                }
                field.onChange(value);
                onChange?.(e);
              }}
              onBlur={(e) => {
                field.onBlur();
                onBlue?.(e)
              }}
              onKeyUp={onKeyUp}
              className={classNames(styles['input'], {
                [classnames || '']: classnames,
                'border-error': error ?? !!fieldState.error,
                'border-[#e2e8f0]': !error && !fieldState.error,
              })}
            />
            {endUnit && (
              <div
                className="absolute top-[15px] right-[12px] font-Gilroy text-[14px] select-none text-[#333]"
              >
                {endUnit}
              </div>
            )}
          </div>
          {(errorText ?? fieldState.error?.message) && (
            <span
              className='absolute top-[82px] font-medium text-error text-sm max-w-full'>
              {errorText ?? fieldState.error?.message}
            </span>
          )}
          {type == 'password' && (
            <div className='cursor-pointer absolute bottom-[15px] right-[14px]' onClick={handleToggleType}>
              {inputType == 'password' ? (
                <EyePasswordIcon/>
              ) : (
                <EyeClosedPasswordIcon/>
              )}
            </div>
          )}
        </div>
      )}
    />
  );
}

export default Input;

export const InputUncontrolled = forwardRef((props: InputHTMLAttributes<any>, ref: React.ForwardedRef<any>) => {
  return <input {...props} className={classNames("w-full text-sm rounded-[15px] px-[16px] py-[15px] border outline-none border-[#e2e8f0]", props.className)} ref={ref}/>
})
