import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import './main.css';
import App from "./App";

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
   <App />
  </React.StrictMode>
);
