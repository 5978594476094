import {Image} from "../image";

export enum UserRole {
  Admin = "admin",
  Agent = "agent",
}

export interface User {
  id: string;
  role: UserRole;
  name: string;
  email: string;
  phone: string;
  isEmailConfirmed: boolean;
  createdAt: string;
  updatedAt: string;
  agentProfile: AgentProfile;
  avatar: Image;
}

interface AgentProfile {
  id: string;
  verified: boolean;
  rejectReason: string;
  businessType: "enterpreter";
  user: string;
  legalName: string;
  address: string;
  city: string;
  postalCode: number;
  ic: string;
  dic: string;
  email: string;
  phone: string;
  description: string;
  web: string;
  instagram: string;
  twitter: string;
  facebook: string;
  tikTok: string
}
