import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import classNames from "classnames";

import { InputUncontrolled } from "../Input/Input";

interface ICounterInputProps {
  control: Control;
  name: string;
}

const CounterInput: FC<ICounterInputProps> = ({
                                                control,
                                                name,
                                              }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => (
        <div className="relative">
          <div
            className={classNames(
              "absolute top-[12px] left-[12px] font-Gilroy text-[18px] font-semibold select-none", {
                "text-blue cursor-pointer": +field.value > 0,
                "text-[#ccc]": +field.value === 0,
              }
            )}
            onClick={() => field.onChange(+field.value > 0 ? +field.value - 1 : 0)}
          >
            -
          </div>
          <InputUncontrolled
            className="text-center"
            value={field.value}
            onChange={(e) => field.onChange(+e.currentTarget.value >= 0 ? +e.currentTarget.value : 0)}
          />
          <div
            className="absolute top-[12px] right-[12px] font-Gilroy text-[18px] text-blue cursor-pointer select-none"
            onClick={() => field.onChange(+field.value >= 0 ? +field.value + 1 : 0)}
          >
            +
          </div>
        </div>
      )}
    />
  );
}

export default CounterInput;
