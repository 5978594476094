import React, {FC} from "react";
import classNames from "classnames";
import styles from "./PropertyStatus.module.scss";
import {PropertyStatus as PropertyStatusType} from "../../../../common/types/dto/property";

interface IPropertyStatusProps {
  status: PropertyStatusType;
}

const PropertyStatus: FC<IPropertyStatusProps> = ({status}) => {
  const text = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const classNameText = status.replaceAll(" ", "-").toLowerCase();
  return (
    <span
      className={
      classNames(
        "h-[24px] min-w-[70px] inline-flex justify-center items-center px-[12px] py-[6px] rounded-[6px] bg-[#48BB78] text-white text-[12px] font-bold",
          styles[`property-status--${classNameText}`]
        )}
    >
      {text}
    </span>);
}

export default PropertyStatus;
