import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";
import {FC} from "react";

interface IPropertyItemProps {
  title: string;
  count: number;
}

const PropertyItem: FC<IPropertyItemProps> = ({title, count}) => {
  return (
    <CardWrapper>
      <div className="text-[#808191] text-[14px] font-medium">{title}</div>
      <div className="text-[#11142d] text-[24px] font-bold">{count}</div>
    </CardWrapper>)
};

const DashboardPropertiesBlock = () => {
  return (
    <div className="flex gap-[20px]">
      <div className="flex-1">
        <PropertyItem
          title="Properties for Sale"
          count={666}
        />
      </div>
      <div className="flex-1">
        <PropertyItem
          title="Properties for Rent"
          count={661}
        />
      </div>
    </div>
  );
}

export default DashboardPropertiesBlock;
