import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { debounce } from "lodash";

import { GOOGLE_MAP_KEY } from "../../../common/constants/env";

import LocationPin from "../../../common/assets/icons/LocationPin"

const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#949494"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#949494"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

const findInAddressComponents = (addressComponents: google.maps.GeocoderAddressComponent [], type: string): google.maps.GeocoderAddressComponent | undefined => {
  return addressComponents.find((ac) => !!ac.types.find((t) => t === type));
}

interface IPropertyDispositionProps {
  control?: any;
  addressStringDefault?: string;
  coordinatesDefault?: google.maps.LatLngLiteral;
}

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <LocationPin />
      {props.data.label}
    </components.Option>
  );
};

const PropertyDisposition: FC<IPropertyDispositionProps> = ({control, addressStringDefault, coordinatesDefault}) => {
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({lat: 50.0847, lng: 14.4203});
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_KEY as string,
    libraries: ['places'],
  })

  const [selectValue, setSelectValue] = useState<{ label: string; value: any; disabled: boolean } | null>()
  const [inputValue, setInputValue] = useState("")

  const selectRef = useRef<any>();

  const geocodeSuccess = (
    responses: google.maps.GeocoderResult[] | null,
    onChange: (...event: any[]) => void
  ) => {
    if (!responses?.length) throw Error("geocodeSuccess responses length error!!!");

    const coordinates = {
      lat: responses[0].geometry.location.lat(),
      lng: responses[0].geometry.location.lng()
    };

    setCenter(coordinates);
    setMarkerPosition(coordinates);
    setSelectValue({value: responses[0].place_id, label: responses[0].formatted_address, disabled: false});
    setInputValue(responses[0].formatted_address)

    const street =
      findInAddressComponents(responses[0].address_components, "route")?.long_name ||
      findInAddressComponents(responses[0].address_components, "street_address")?.long_name
    const houseNumber =
      findInAddressComponents(responses[0].address_components, "street_number")?.long_name ||
      findInAddressComponents(responses[0].address_components, "premise")?.long_name
    const city = findInAddressComponents(responses[0].address_components, "administrative_area_level_2")?.long_name;
    const state = findInAddressComponents(responses[0].address_components, "administrative_area_level_1")?.long_name;
    const postalCode = findInAddressComponents(responses[0].address_components, "postal_code")?.long_name;
    const country = findInAddressComponents(responses[0].address_components, "country")?.long_name;
    const address = {
      street,
      houseNumber,
      city,
      state,
      postalCode: postalCode ? Number.parseInt(postalCode?.replaceAll(" ", "")) : undefined,
      country,
      coordinates: {
        lat: coordinates.lat,
        lon: coordinates.lng,
      }
    }
    onChange?.(address);
  }

  const onClick = useCallback(function callback(e: google.maps.MapMouseEvent, onChange: (...event: any[]) => void) {
    const coordinates = {lat: e.latLng!.lat(), lng: e.latLng!.lng()};
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      location: coordinates,
      language: "cs"
    }, (responses) => {
      if (responses) {
        const country = findInAddressComponents(responses[0].address_components, "country")?.short_name;

        if (country === "CZ") {
          geocodeSuccess(responses, onChange);
        }
      }
    });
  }, []);

  const loadOptions = (
    inputVal: string,
    callback: (options: any[]) => void
  ) => {
    const autocompleteService = new google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions({
        input: inputVal,
        //todo localization
        language: "cs",
        region: "cz",
        componentRestrictions: { country: "cz" },
      }
    ).then((data) => {
      const cbValue = data.predictions.map((p) => ({label: p.description, value: p.place_id}))
      callback(cbValue);
    });
  }

  const loadOptionsDebounce = useCallback(debounce(loadOptions, 800), [])

  const onChange = (value: any, onChange: (...event: any[]) => void) => {
    if(!value) return
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({placeId: value, language: "cs"}, (responses) => geocodeSuccess(responses, onChange));
  }

  useEffect(() => {
    if (addressStringDefault) {
      setSelectValue({label: addressStringDefault, value: "default", disabled: false});
      setInputValue(addressStringDefault);
    }
  }, [addressStringDefault]);

  useEffect(() => {
    if (coordinatesDefault) {
      setCenter(coordinatesDefault);
      setMarkerPosition(coordinatesDefault)
    }
  }, [coordinatesDefault]);

  const onInputChange = (inputVal: any, { action }: any) => {
    if (action === "input-change") {
      setInputValue(inputVal);
    }
  };

  const onFocus = () => selectValue && selectRef.current?.select?.inputRef?.select();

  if (!isLoaded) return null;
  if (loadError) return <div>Error loading maps</div>;

  return (
    <Controller name="address" control={control} render={({field}) => {
      const handleChange = (option: any) => {
        setSelectValue(option);
        setInputValue(option ? option.label : '');
        onChange(option?.value, field.onChange)
      };
      return (
        <div>
          <div className="grid grid-cols-2 gap-[20px]">
            <div>
              <div className="text-[20px] text-[#333] font-semibold mb-[35px]">Disposition</div>
              <div className="text-sm text-[#333] mb-1.5">Address</div>
              <AsyncSelect
                ref={selectRef}
                value={selectValue}
                inputValue={inputValue}
                isClearable={true}
                onInputChange={onInputChange}
                onFocus={onFocus}
                controlShouldRenderValue={false}
                components={{
                  Input,
                  Option
                }}
                cacheOptions
                isOptionDisabled={(option) => option.disabled}
                loadOptions={loadOptionsDebounce}
                onChange={(newValue) => {
                  handleChange(newValue)
                  onChange(newValue, field.onChange)
                }}
                placeholder="Start typing address"
                classNames={{
                  container: () => 'w-[80%] h-[50px] rounded-[15px] border border-[#E2E8F0] leading-[18px]',
                  control: () => 'h-[50px] p-4',
                  menu: () => 'bg-white p-2 mt-1 border-0',
                  menuList: () => 'max-h-[300px] overflow-auto text-[#666] text-[14px] leading-6',
                  singleValue: () => 'text-[#666] text-[14px]',
                  indicatorsContainer: () => 'hidden invisible z-0',
                  placeholder: () => 'text-[#AAAAAA80]',
                  noOptionsMessage: () => 'hidden invisible z-0',
                }}
                unstyled={true}
              />
            </div>
            <div className="h-[300px]">
              <GoogleMap
                center={center}
                onClick={(e) => onClick(e, field.onChange)}
                zoom={15}
                mapContainerStyle={{width: '100%', height: '100%', borderRadius: 15}}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  clickableIcons: false,
                  backgroundColor: "#e9e9e9",
                  draggableCursor: '"", default;',
                  draggingCursor: '"", default;',
                  styles: mapStyle,
                }}
              >
                {markerPosition && <MarkerF position={markerPosition}/>}
              </GoogleMap>
            </div>
          </div>
        </div>
      )
    }}/>
  );
}

export default PropertyDisposition;
