import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

interface FormValues {
  duration: {
    label: string;
    value: number;
  };
}

const defaultValues = {
  duration: {
    label: "1 month",
    value: 1,
  }
}

const useBillingModalForm = () => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    control,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues,
  });

  const { mutateAsync } = useMutation(async ({ payload }: {
    payload: number, //todo make interface
  }) => {
    setLoader(true);
    const data = await new Promise<number>((resolve) => {
      setTimeout(() => {
        resolve(payload);
      }, 2000);
    });
    setLoader(false);
    return data;
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const data = await mutateAsync({
      payload: values.duration.value,
    }, {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

    alert(data)
  }

  return {
    control,
    watch,
    handleSubmit: handleSubmit(onSubmit),
  };
}

export default useBillingModalForm;
