interface IPropertyFieldsColumnProps {
  items?: {
    label: string;
    value: string;
    unit?: string;
  }[] | null;
  columnCount?: number;
}

const PropertyFieldsColumn = ({items, columnCount = 1}: IPropertyFieldsColumnProps) => {
  if (!items) return null;
  return (
    <div className={`grid grid-cols-${columnCount} gap-[16px]`}>
      {items?.map((field, index) => {
        const isIsoDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(field.value);
        const isMoney = field?.unit?.includes('Kč')

        const formattedValue = isIsoDate
          ? new Intl.DateTimeFormat('default', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(new Date(field.value))
          : isMoney ? field.value.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : field.value;

        return (
          <div className="flex gap-[8px] leading-[1.2]" key={index}>
            <div className="flex-1 text-[#666]">{field.label}</div>
            <div className="flex-1 text-[#333]">{formattedValue} {field.unit}</div>
          </div>
        );
      })}
    </div>
  );
}

export default PropertyFieldsColumn;
