import {create} from "zustand";
import {ForgotPasswordPageStepType} from "../types/step";

interface IForgotPasswordStore {
  step: ForgotPasswordPageStepType;
  email: string;
  pin: string;
  setStep: (newStep: ForgotPasswordPageStepType) => void;
  setEmail: (newEmail: string) => void;
  setPin: (newPin: string) => void;
}

const useForgotPasswordStore = create<IForgotPasswordStore>((set) => ({
  step: "email",
  email: "",
  pin: "",
  setStep: (newStep) => set((prevState) => ({
    ...prevState,
    step: newStep,
  })),
  setEmail: (newEmail) => set((prevState) => ({
    ...prevState,
    email: newEmail,
  })),
  setPin: (newPin) => set((prevState) => ({
    ...prevState,
    pin: newPin,
  })),
}))


export default useForgotPasswordStore;
