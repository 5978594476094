import { ComponentType, useMemo } from "react";

import { UserRole } from "../../../common/types/dto/user";
import { ISvgProps } from "../../../common/types/svg";

import useAuthStore from "../../../common/stores/useAuthStore";

import DashboardIcon from "../../../common/assets/icons/menu/DashboardIcon";
import PropertyIcon from "../../../common/assets/icons/menu/PropertyIcon";
import BillingIcon from "../../../common/assets/icons/menu/BillingIcon";
import MessagesIcon from "../../../common/assets/icons/menu/MessagesIcon";
import AccountIcon from "../../../common/assets/icons/menu/AccountIcon";
import SupportIcon from "../../../common/assets/icons/menu/SupportIcon";
import SettingsIcon from "../../../common/assets/icons/menu/SettingsIcon";

interface IMenuItemProps {
  icon: ComponentType<ISvgProps>;
  label: string;
  href: string;
}

const menuItemsAdmin: IMenuItemProps[] = [
  {
    icon: PropertyIcon,
    label: "Property",
    href: "/"
  },
  {
    icon: MessagesIcon,
    label: "Messages",
    href: "/messages"
  },
  {
    icon: AccountIcon,
    label: "Agents",
    href: "/agents"
  },
  {
    icon: SupportIcon,
    label: "Support",
    href: "/support"
  },
  {
    icon: SettingsIcon,
    label: "Settings",
    href: "/settings"
  },
];

const menuItemsAgent: IMenuItemProps[] = [
  {
    icon: DashboardIcon,
    label: "Dashboard",
    href: "/"
  },
  {
    icon: PropertyIcon,
    label: "Property",
    href: "/properties"
  },
  {
    icon: BillingIcon,
    label: "Billing",
    href: "/billing"
  },
  {
    icon: MessagesIcon,
    label: "Messages",
    href: "/messages"
  },
  {
    icon: AccountIcon,
    label: "Account",
    href: "/account"
  },
  {
    icon: SupportIcon,
    label: "Support",
    href: "/support"
  },
];

const useMenuItems = () => {
  const user = useAuthStore((state) => state.user);

  const menuItems = useMemo(() => {
    if(!user) return [];
    return user.role === UserRole.Admin ? menuItemsAdmin : menuItemsAgent;
  }, [user]);

  return {
    menuItems,
  }
}

export default useMenuItems;
