import {Outlet} from 'react-router-dom';
import classNames from "classnames";

interface IAuthLayout {
  borderColor?: "blue" | "white";
}

const AuthLayout = ({borderColor}: IAuthLayout) => {
  return (
    <div className={classNames(
      "h-dvh p-8",
      {
        "bg-blue": borderColor === "blue",
        "bg-white": !borderColor || borderColor === "white"
      })}>
      <div className="h-full rounded-[20px] bg-white">
        <Outlet/>
      </div>
    </div>
  )
}

export default AuthLayout;
