import React from "react";
import ReactSelect from 'react-select';
import { Controller, ControllerProps } from "react-hook-form";

// todo remove any
interface ISelectProps<TFormData extends Record<string, any>>
  extends Pick<ControllerProps<TFormData>, 'control' | 'name' | 'defaultValue' | 'disabled'> {
  options: any[],
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  isOptionSelected?: (option: any, selectValue: any) => boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  placeholder?: string;
  classNames?: Object;
  unstyled?: boolean;
  withAll?: boolean;
}

function Select<TFormData extends Record<string, any>>
({
   name,
   control,
   defaultValue,
   disabled,
   options,
   getOptionLabel,
   getOptionValue,
   isMulti,
   closeMenuOnSelect,
   hideSelectedOptions,
   isOptionSelected,
   placeholder,
   classNames,
   unstyled = true,
   withAll = false,
 }: ISelectProps<TFormData>): React.ReactElement {
  const classes = {
    container: () => 'w-full h-[38px] bg-white rounded-[15px] border border-[#E2E8F0] leading-[18px]',
    control: () => 'w-full px-4 pb-[2px]',
    menu: () => 'bg-white p-4 pr-1 mt-1 rounded-[15px] border border-[#E2E8F0]',
    menuList: () => 'max-h-[300px] overflow-auto text-[#666] text-[14px] leading-6',
    singleValue: () => 'text-[#666] text-[14px]',
    placeholder: () => 'text-[#666]'
  }

  const optionsWithAll = withAll && [{
    label: 'All',
    value: null,
  }].concat(options)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field}) => (
        <ReactSelect
          classNames={{
            ...classes,
            ...classNames
          }}
          value={field.value}
          options={optionsWithAll || options}
          isDisabled={disabled}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          isOptionSelected={isOptionSelected}
          placeholder={placeholder}
          unstyled={unstyled}
        />
      )}
    />
  );
}

export default Select;
