import {useForm} from "react-hook-form";
import {IForgotPasswordEmail} from "../types/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {forgotPasswordEmailFormSchema} from "../validations/schema";
import useForgotPasswordStore from "../stores/useForgotPasswordStore";
import {postOtpPasswordResetSend} from "../../../api/services/otp/requests";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

const useFormForgotPasswordEmail = () => {
  const {setStep, setEmail} = useForgotPasswordStore((state) => state);
  const {setLoader} = useGlobalLoaderStore((state) => state);

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    setError
  } = useForm<IForgotPasswordEmail>({
    mode: "onBlur",
    defaultValues: {email: ''},
    resolver: zodResolver(forgotPasswordEmailFormSchema),
  });

  const onSubmit = async (value: IForgotPasswordEmail) => {
    try {
      setLoader(true);
      await postOtpPasswordResetSend({email: value.email});
      setEmail(value.email);
      setStep("pin");
    } catch (error: any) {
      const message = error?.response?.data?.message || "Request failed";
      setError("email", {message})
    } finally {
      setLoader(false);
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  }
}

export default useFormForgotPasswordEmail;
