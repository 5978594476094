import useFormForgotPasswordEmail from "../hooks/useFormForgotPasswordEmail";
import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";

const ForgotPasswordEmailForm = () => {
  const {handleSubmit, control, isValid} = useFormForgotPasswordEmail();

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-[318px]">
        <Input
          label="Email"
          name="email"
          control={control}
          placeholder="Enter your email"
        />
      </div>
      <div className="flex-center">
        <div className="mt-[46px] w-[318px]">
          <Button
            type="submit"
            size="large"
            disabled={!isValid}
            fullWidth={true}
          >
            Send code
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ForgotPasswordEmailForm;
