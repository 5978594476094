import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { postMediaUploadImage } from "../../../api/services/media/requests";
import {
  deletePropertyByIdGallery,
  patchPropertyById,
  patchPropertyByIdGallery
} from "../../../api/services/property/requests";

import { IPhotosAndDescriptionPropertyForm } from "../types/form";
import { Property } from "../../../common/types/dto/property";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

const defaultDescription = [
  {
    language: "en",
    value: "",
  },
  {
    language: "cz",
    value: "",
  }
]

const usePhotosAndDescriptionPropertyForm = (property?: Property) => {
  const {setLoader} = useGlobalLoaderStore();
  const [gallery, setGallery] = useState<any[]>([]);
  const [deleteGallery, setDeleteGallery] = useState<string[]>([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);

  useEffect(() => {
    if (property?.gallery) {
      setGallery(property?.gallery.map((image) => ({
          id: image.image.id,
          file: image,
          src: image.image.url,
          toUpload: false,
        }))
      );

      const index = property.gallery.findIndex((g) => g.image.id === property.mainImage?.id);
      setMainImageIndex(index === -1 ? 0 : index);
    }
  }, [property]);

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<IPhotosAndDescriptionPropertyForm>({
    mode: "onBlur",
    defaultValues: {
      youtubeUrl: "",
      description: [
        {
          language: "en",
          value: "",
        },
        {
          language: "cz",
          value: "",
        }
      ],
    },
  })

  const onSubmit = async (values: IPhotosAndDescriptionPropertyForm) => {
    setLoader(true);
    const uploadedImages = [];
    for(const image of gallery) {
      uploadedImages.push(new Promise((resolve, _) => {
        if(image.toUpload) {
          console.log({image})
          resolve(postMediaUploadImage({image: image.file}))
        }
        else {
          resolve(image)
        }
      }))
    }

    const images = await Promise.allSettled(uploadedImages);

    const imagePayload = {
        mainImage: undefined,
        images: undefined,
    };

    if(images[mainImageIndex] && images[mainImageIndex].status === "fulfilled") {
      // @ts-ignore
      imagePayload.mainImage = images[mainImageIndex].value.id;
    }
    else imagePayload.mainImage = undefined;

    // @ts-ignore
    imagePayload.images = images.filter(({status}) => status === "fulfilled" ).map(({value}) => ({
      id: value.id
    }));

    if(property) {
      try {
        await Promise.allSettled([
          patchPropertyById(property.id, values),
          patchPropertyByIdGallery(property.id, imagePayload),
          deleteGallery.length > 0 && deletePropertyByIdGallery(property.id, {images: deleteGallery}),
        ]);
      } catch (error) {
        throw error
      }
    }
    setLoader(false);
  }

  useEffect(() => {
    property && reset({
      youtubeUrl: property.youtubeUrl,
      description: property.description?.length ? property.description : defaultDescription,
    });
  }, [property]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    gallery,
    setGallery,
    mainImageIndex,
    setMainImageIndex,
    setDeleteGallery,
  }
}

export default usePhotosAndDescriptionPropertyForm;
