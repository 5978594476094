import {AxiosResponse} from "axios";
import axiosInstance from "../../axios";
import {IResponse} from "../../../common/types/response";
import {CategoryFull, CategorySchema} from "../../../common/types/dto/category";

export const getCategoryList = async () => {
  const response: AxiosResponse<IResponse<CategoryFull[]>> = await axiosInstance.get("/category/list");
  return response.data.data;
}

export const getCategorySchemaById = async (categoryId: string) => {
  const response: AxiosResponse<IResponse<CategorySchema>> = await axiosInstance.get(`/category/${categoryId}/schema`);
  return response.data.data;
}
