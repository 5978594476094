import { useEffect } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";

import { PropertyAddress } from "../../../common/types/dto/property";

import { patchPropertyById } from "../../../api/services/property/requests";
import { IPatchPropertyByIdPayload } from "../../../api/services/property/types";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

const usePropertyDispositionForm = (propertyId: string, defaultValue?: PropertyAddress) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { mutateAsync } = useMutation(async ({ propertyId, payload }: {
    propertyId: string,
    payload: IPatchPropertyByIdPayload
  }) => {
    setLoader(true);
    const data = await patchPropertyById(propertyId, payload);
    setLoader(false);
    return data;
  });

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<{ address: PropertyAddress | null }>({
    defaultValues: {
      address: defaultValue,
    }
  });


  useEffect(() => {
    reset({ address: defaultValue });
  }, [defaultValue]);

  const onSubmit = async (value: { address: PropertyAddress | null }) => {
    if (!value.address?.street || !value.address?.houseNumber) {
      setError('Please select a building')
      throw new Error('Please select a building')
    }
    await mutateAsync({
        propertyId,
        payload: { address: value.address! },
      }, {
        onError: (error: any) => {
          setError(error.response.data.message);
          setLoader(false);
        }
      });
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
  }
}

export default usePropertyDispositionForm;
