import React, { useEffect, useRef } from "react";

interface ChatMessageProps {
  avatar: string | null;
  name: string;
  body: string;
  createdAt: string;
  read: boolean;
  isOwner?: boolean;
  markRead: () => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ avatar, name, body,  createdAt, read, isOwner, markRead }) => {
  const messageRef = useRef<HTMLDivElement | null>(null);

  const avatarElem = avatar ? (
    <img
      src={avatar}
      width={36}
      height={36}
      alt="user icon"
      className="h-[36px] w-[36px] rounded-[12px] object-cover"
    />
  ) : (
    <div className="flex-center rounded-[12px] w-[36px] h-[36px] bg-blue text-white">{name?.charAt(0)}</div>
  );

  const time = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(createdAt));

  useEffect(() => {
    if (read || isOwner) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          const timer = setTimeout(() => {
            markRead();
          }, 5000);

          return () => clearTimeout(timer);
        }
      },
      { threshold: 1.0 }
    );

    if (messageRef.current) {
      observer.observe(messageRef.current);
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
      }
    };
  }, [read, markRead]);

  return isOwner ? (
    <div ref={messageRef} className="flex justify-end items-center gap-[10px]">
      <div className='relative pl-[10px] pr-[20px] pt-[10px] pb-[16px] rounded-[10px] bg-[#F5F5F566]'>
        <span className="text-[14px]">{body}</span>
        <div className="absolute bottom-0 left-[6px] text-[10px] text-[#AAAAAA80] text-center">{time}</div>
      </div>
      <div>
        {avatarElem}
      </div>
    </div>
  ) : (
    <div ref={messageRef} className="flex justify-start items-center gap-[10px]">
      <div>
        {avatarElem}
      </div>
      <div className={`relative pl-[10px] pr-[20px] pt-[10px] pb-[16px] rounded-[10px] ${read ? 'bg-[#F5F5F566]' : 'bg-[#006CFB0D]'}`}>
        <span className="text-[14px]">{body}</span>
        <div className="absolute bottom-0 right-[6px] text-[10px] text-[#AAAAAA80] text-center">{time}</div>
      </div>
    </div>
  )
}

export default ChatMessage;
