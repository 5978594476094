import React from "react";
import {RouteObject} from "react-router";
import AuthLayout from "../pages/auth/AuthLayout";
import LoginPage from "../pages/auth/LoginPage";
import CreateAccountPage from "../pages/auth/CreateAccountPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import VerifyEmailPage from "../pages/auth/VerifyEmailPage";
import NotFoundPage from "../pages/NotFoundPage";

const routesUnauthorized: RouteObject[] = [
  {
    element: <AuthLayout/>,
    children: [
      {
        path: "/",
        element: <LoginPage/>,
      },
      {
        path: "*",
        element: <NotFoundPage/>
      }
    ]
  },
  {
    element: <AuthLayout borderColor="blue"/>,
    children: [
      {
        path: "/create-account",
        element: <CreateAccountPage/>,
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage/>,
      },
      {
        path: "/verify-email",
        element: <VerifyEmailPage/>,
      },
    ]
  },
]

export default routesUnauthorized;
