import axios from "axios";
import {API_URL} from "../common/constants/env";
import useAuthStore from "../common/stores/useAuthStore";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});



axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = useAuthStore.getState().accessToken;
    if(accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.message === "Unauthorized"){
      useAuthStore.getState().logout();
      window.location.href = "/";
    }
    throw error;
  }
);

export default axiosInstance;
