import { Dispatch, FC, SetStateAction } from "react";
import { Control } from "react-hook-form";

import Input from "../../../common/components/Input/Input";

import PropertyEditPhotos from "./PropertyEditPhotos";
import PropertyDescriptionInput from "./PropertyDescriptionInput";

interface IPropertyPhotosAndDescriptionProps {
  control: Control<any>;
  gallery: any[];
  setGallery: Dispatch<SetStateAction<any[]>>;
  mainImageIndex: number;
  setMainImageIndex: Dispatch<SetStateAction<number>>;
  setDeleteGallery: Dispatch<SetStateAction<string[]>>;
}

const PropertyPhotosAndDescription: FC<IPropertyPhotosAndDescriptionProps> = ({
                                                                                control,
                                                                                gallery,
                                                                                setGallery,
                                                                                mainImageIndex,
                                                                                setMainImageIndex,
                                                                                setDeleteGallery,
                                                                              }) => {

  return (
    <div className="flex flex-col gap-[35px] ">
      <div className="text-[20px] text-[#333] font-semibold">Photos and description</div>
      <div>
        <div className="text-[#666] mb-[15px]">Please upload at least 4 photos of the property.
          High-quality images help attract more potential clients and provide a better view of your listing.
        </div>
        <PropertyEditPhotos
          control={control}
          gallery={gallery}
          setGallery={setGallery}
          mainImageIndex={mainImageIndex}
          setMainImageIndex={setMainImageIndex}
          setDeleteGallery={setDeleteGallery}
        />
      </div>
      <div className="grid grid-cols-3 gap-x-[40px] gap-y-[18px]">
        <Input
          label="Link to youtube video"
          name="youtubeUrl"
          control={control}
          placeholder="https://"
        />
      </div>
      <div>
        <PropertyDescriptionInput
          name="description"
          control={control}
          label="Description"
        />
      </div>

    </div>
  );
}

export default PropertyPhotosAndDescription;
