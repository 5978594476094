import { create } from "zustand";

import { IMessage } from "../types/chat";

interface IWebsocketChatStore {
  roomId: string;
  setRoomId: (newRoomId: string) => void;
  message: string;
  setMessage: (newMessage: string) => void;
  messages: IMessage[];
  setMessages: (newMessages: IMessage[]) => void;
  markMessageRead: (messageId: string) => void;
  resetStore: () => void;
  page: number;
  setPage: (newPage: number | ((prevPage: number) => number)) => void;
  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;
  loadMoreMessages: (newMessages: IMessage[], hasMore: boolean) => void;
}

const initialState = {
  roomId: '',
  message: '',
  messages: [],
  page: 1,
  hasMore: true,
}

const useWebsocketChatStore = create<IWebsocketChatStore>((set) => ({
  roomId: '',
  setRoomId: (newRoomId) => set((prevState) => ({
    roomId: newRoomId,
    ...(prevState.roomId !== newRoomId && {messages: [], page: 1, hasMore: true, message: '' })
  })),
  message: '',
  setMessage: (newMessage) => set({ message: newMessage }),
  messages: [],
  setMessages: (newMessages, reset = false) => set((prevState) => ({
    messages: reset ? newMessages : [...prevState.messages, ...newMessages],
  })),
  markMessageRead: (messageId) => set((prevState) => ({
    messages: prevState.messages.map((msg) =>
      msg.id === messageId ? { ...msg, read: true } : msg
    )
  })),
  resetStore: () => set (() => initialState),
  page: 1,
  setPage: (newPage) => set((prevState) => ({
    page: typeof newPage === 'function' ? newPage(prevState.page) : newPage
  })),
  hasMore: true,
  setHasMore: (newHasMore) => set({ hasMore: newHasMore }),
  loadMoreMessages: (newMessages, hasMore) => set((prevState) => ({
    messages: [...newMessages, ...prevState.messages],
    hasMore
  })),
}));

export default useWebsocketChatStore;
