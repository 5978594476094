import * as zod from "zod"

export const forgotPasswordEmailFormSchema = zod.object({
  email: zod.string().email("Enter valid email"),
});

export const forgotPasswordPinFormSchema = zod.object({
  pin: zod.string().length(4),
});


export const forgotPasswordNewPasswordFormSchema = zod.object({
  password: zod.string().min(8),
  confirmPassword: zod.string().min(8),
}).refine(
  (values) => {
    return values.password === values.confirmPassword;
  },
  {
    message: "Passwords must match!",
    path: ["confirmPassword"],
  }
);
