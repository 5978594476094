import {FC, useRef} from "react";
import Slider from "react-slick";
import {PropertyImage} from "../../../common/types/dto/property";

interface IPropertyCarouselProps {
  items?: PropertyImage[];
}

const PropertyCarousel: FC<IPropertyCarouselProps> = ({items}) => {
  let sliderRef = useRef<Slider | null>(null);

  const sliderSettings = {
    customPaging: (index: number) => !!items ? (
      <div className="h-[50px] w-[50px]">
        <img
          src={items[index].image.url}
          width={50}
          height={50}
          alt={index.toString()}
          className="h-[50px] w-[50px] object-cover"
        />
      </div>
    ) : <div></div>,
    dots: true,
    dotsClass: "!flex gap-[10px] overflow-auto",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <div className="slider-container">
      <Slider
        ref={(slider) => {
          sliderRef.current = slider;
        }}
        {...sliderSettings}
      >
        {items?.map((image) => (
          <div key={image.image.id} className="aspect-[2]">
            <img src={image.image.url} alt={image.image.name} className="w-full h-full object-cover rounded-[10px]"/>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default PropertyCarousel;
