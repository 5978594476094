import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current?.contains(event.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed top-0 left-0 bottom-0 right-0 flex-center bg-[#00000040]" onClick={handleClickOutside}>
      <div ref={modalRef} className="bg-white p-[25px] rounded-[10px] w-auto min-w-[621px] ">
        <div>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};
export default Modal;
