import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { ILogin } from "../types/form";

import { postAuthLogin } from "../../../api/services/auth/requests";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useAuthStore from "../../../common/stores/useAuthStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

const useFormLogin = () => {
  const [loginError, setLoginError] = useState<string>("");
  const { setAccessToken } = useAuthStore((state) => state);
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation((payload: ILogin) => postAuthLogin(payload));

  const {
    control,
    handleSubmit,
  } = useForm<ILogin>({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      isAdminPanel: true,
    },
  })

  const onSubmit = async (value: ILogin) => {
    try {
      setLoader(true);
      const { accessToken } = await mutateAsync(value, {
        onError: (error: any) => {
          setError(error.response.data.message);
          setLoader(false);
        }
      });
      setAccessToken(accessToken);
    } catch (error: any) {
      const needConfirmEmail = error?.response?.data?.data?.needConfirmEmail;
      if(needConfirmEmail) {
        setLoader(false);
        navigate(`/verify-email?email=${btoa(value.email)}`);
      }

      // todo error type
      const message = error?.response?.data?.message || "Login failed";
      setLoginError(message);
    }
    finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    loginError,
  };
}

export default useFormLogin;
