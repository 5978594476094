import {useMemo} from "react";
import {CategorySchemaGroupItem} from "../../../common/types/dto/category";

//todo change any
const usePropertyFieldsFromSchema = (fields?: any[], schema?: CategorySchemaGroupItem) => {
  return useMemo(() => {
    if (!fields || !schema) return null;
    const schemaFieldIds = schema.fields.map((f: any) => f.id);
    const fieldsInSchema = fields.filter((f: any) => schemaFieldIds.includes(f.field.id));

    return fieldsInSchema.map((f: any) => {
      switch (f.field.type) {
        case "TAG_SELECT": {
          return {
            label: f.field.label,
            value: f.tag?.label,
            unit: f.field.meta?.unit
          };
        }
        case "TAG_MULTI_SELECT": {
          return {
            label: f.field.label,
            value: f.tags.map((t: any) => t.label).join(", "),
            unit: f.field.meta?.unit
          };
        }
        default:
          return {
            label: f.field.label,
            value: f.value,
            unit: f.field.meta?.unit
          };
      }
    })
  }, [fields, schema]);
}

export default usePropertyFieldsFromSchema;
