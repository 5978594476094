import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";

const DashboardTotalRevenueBlock = () => {

  const revenueData = [
    {
      name: 'January',
      price: 4000,
    },
    {
      name: 'February',
      price: 3000,
    },
    {
      name: 'March',
      price: 9800,
    },
    {
      name: 'April',
      price: 2780,
    },
    {
      name: 'May',
      price: 1890,
    },
    {
      name: 'June',
      price: 3800,
    },
    {
      name: 'July',
      price: 3490,
    },
    {
      name: 'August',
      price: 4000,
    },
    {
      name: 'September',
      price: 7500,
    },
    {
      name: 'October',
      price: 9800,
    },
    {
      name: 'November',
      price: 3908,
    },
    {
      name: 'December',
      price: 4800,
    },
  ];

  return (
    <CardWrapper>
      <div className="text-[#11142d] text-[18px] font-semibold">Total Revenue</div>
      <div className="text-[#11142d] text-[28px] font-bold mt-[20px]">$236,535</div>
      <div className="h-[250px] mt-[16px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={revenueData}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{fill: 'transparent'}} />
            <Bar dataKey="price" fill="#216fed" barSize={11}  />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </CardWrapper>
  );
}

export default DashboardTotalRevenueBlock;
