import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";

const DashboardOverviewBlock = () => {
  const overviewData = [
    {
      name: 'January',
      sold: 4000,
      rent: 2400,
    },
    {
      name: 'February',
      sold: 3000,
      rent: 1398,
    },
    {
      name: 'March',
      sold: 2000,
      rent: 9800,
    },
    {
      name: 'April',
      sold: 2780,
      rent: 3908,
    },
    {
      name: 'May',
      sold: 1890,
      rent: 4800,
    },
    {
      name: 'June',
      sold: 2390,
      rent: 3800,
    },
    {
      name: 'July',
      sold: 3490,
      rent: 4300,
    },
    {
      name: 'August',
      sold: 4000,
      rent: 2400,
    },
    {
      name: 'September',
      sold: 7500,
      rent: 1398,
    },
    {
      name: 'October',
      sold: 2000,
      rent: 9800,
    },
    {
      name: 'November',
      sold: 2780,
      rent: 3908,
    },
    {
      name: 'December',
      sold: 1890,
      rent: 4800,
    },
  ];

  return (
    <CardWrapper>
      <div className="text-[#11142d] text-[18px] font-semibold">Overview</div>
      <div className="h-[250px] mt-[28px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={overviewData}
          >
            <CartesianGrid horizontal={true} vertical={false} strokeDasharray={5} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sold" stroke="#6c5dd3" dot={false} activeDot={false} strokeWidth={2} />
            <Line type="monotone" dataKey="rent" stroke="#216fed" dot={false} activeDot={false} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CardWrapper>
  );
}

export default DashboardOverviewBlock;
