import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { PROPERTY_STATUS_SELECT } from "../../common/constants/property";

import { UserRole } from "../../common/types/dto/user";
import { PropertyStatus } from "../../common/types/dto/property";
import { CategoryFull } from "../../common/types/dto/category";
import { ISelectValue } from "../../common/types/select";

import { getPropertyOwned } from "../../api/services/property/requests";
import { getCategoryList } from "../../api/services/category/requests";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../common/stores/useAuthStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import PropertyCard from "../../common/components/PropertyCard/PropertyCard";
import Select from "../../common/components/Select/Select";
import TreeSelectAccordion from "../../common/components/TreeSelectAccordion/TreeSelectAccordion";
import Dropdown from "../../common/components/Dropdown/Dropdown";

function getSelectedCategoriesIds(obj: any) {
  return Object.keys(obj).filter(id => obj[id].checked);
}

function collectLeafCategoryIds(category: CategoryFull, checkedIds: string[]): string[] {
  let result = [];

  if (category.children.length === 0) {
    if (checkedIds.includes(category.id)) {
      result.push(category.id);
    }
  }

  for (const child of category.children) {
    result = result.concat(collectLeafCategoryIds(child, checkedIds));
  }

  return result;
}

function collectLeafCategoriesIdsFull(categories: CategoryFull[] | undefined, checkedIds: string[]): string[] {
  if (!categories) return []
  let resultFull: string[] = []

  for (const category of categories) {
    const currentArray: string[] = collectLeafCategoryIds(category, checkedIds);
    resultFull.push(...currentArray)
  }

  return resultFull;
}

const PropertiesPage = () => {
  const [citiesOptions, setCitiesOptions] = useState<any[]>([]);

  const { user } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { control, setValue, watch } = useForm<{
    categoriesIds: string[] | null
    status: ISelectValue<PropertyStatus> | null
    city: {
      label: string;
      value: string;
    } | null;
  }>({
    defaultValues: {
      categoriesIds: null,
      status: null,
      city: null,
    }
  });

  const categoriesIds = watch("categoriesIds");
  const status = watch("status");
  const city = watch("city");

  const { data: categories } = useQuery(["category"], async () => {
    setLoader(true);
    const data = await getCategoryList();
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const { data: properties } = useQuery(["properties", user, status, city, categoriesIds], async () => {
      setLoader(true);

      const payload = {
        page: 1,
        limit: 1000,
        ...(!!status?.value && {status: status.value}),
        ...(!!city?.value && {city: city.value}),
        ...(!!categoriesIds && {categories: categoriesIds}),
      }

      let data = await getPropertyOwned(payload);
      // if (user?.role === UserRole.Admin) data = await getProperty(payload);
      // else data = await getPropertyOwned(payload);

      setLoader(false);
      return data;
    },
    {
      enabled: !!user,
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  const handleCategorySelection = (selectedCategories: any) => {
    const selectedCategoriesIds = getSelectedCategoriesIds(selectedCategories)
    const leafCategoriesIds = collectLeafCategoriesIdsFull(categories, selectedCategoriesIds)

    setValue("categoriesIds", leafCategoriesIds);
  };

  const selectedCategoriesCountString = categoriesIds?.length ? ` (${categoriesIds?.length})` : '';

  useEffect(() => {
    if (status?.value || city?.value || categoriesIds?.length) return;
    const citiesAll = properties?.items.filter(p => !!p.address?.city).map(p => p.address?.city)
    setCitiesOptions([...new Set(citiesAll)].map(c => ({
      label: c,
      value: c,
    })) || [])
  }, [properties])

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <div className="flex items-center gap-[20px]">
        <div className="w-[300px]">
          <Select
            control={control}
            classNames={{
              container: () => 'w-full h-[38px] bg-white rounded-[6px] border border-[#E2E8F0] leading-[18px]',
              singleValue: () => 'text-[16px] text-[#666]',
            }}
            name="status"
            options={PROPERTY_STATUS_SELECT}
            placeholder="Status"
            withAll
          />
        </div>
        <div className="w-[300px]">
          <Select
            control={control}
            classNames={{
              container: () => 'w-full h-[38px] bg-white rounded-[6px] border border-[#E2E8F0] leading-[18px]',
              singleValue: () => 'text-[16px] text-[#666]',
            }}
            name="city"
            options={citiesOptions}
            placeholder="City"
            withAll
          />
        </div>
        <Dropdown className="max-w-[300px] rounded-[6px]" title={`Categories ${selectedCategoriesCountString}`} buttonClass='text-[#666]'>
          {categories ? (
              <TreeSelectAccordion
                data={categories as CategoryFull[]}
                onSelected={handleCategorySelection}
              />
            ) : <div />
          }
        </Dropdown>
      </div>
      <div className="mt-[25px]">
        <CardWrapper>
            <div className="flex items-center gap-[10px] mb-[20px]">
              <div className="text-[#11142d] text-[18px] font-semibold">Properties</div>
              {user?.role !== UserRole.Admin && (<Link to="/property/create">
                <div
                  className="flex-center h-[24px] w-[24px] bg-blue rounded-full text-white text-[18px] font-semibold cursor-pointer">
                  +
                </div>
              </Link>)}
            </div>
          <div>
            <div className="grid grid-cols-3 gap-x-[56px] gap-y-[10px]">
              {properties?.items.map((property) => (
                <div key={property.id}>
                  <Link to={`/property/${property.id}`}>
                    <PropertyCard property={property}/>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </CardWrapper>
      </div>
    </div>
  );
}

export default PropertiesPage;
