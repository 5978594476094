import React, { FC } from "react";

import { PropertyShort } from "../../types/dto/property";

import { getAddressString } from "../../utils/address";

import PropertyStatus from "../../../features/property/components/PropertyStatus/PropertyStatus";

import EyeIcon from "../../assets/icons/EyeIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import MessagesIcon from "../../assets/icons/menu/MessagesIcon";
import HeartIcon from "../../assets/icons/HeartIcon";
import LocationIcon from "../../assets/icons/LocationIcon";
import NoImageIcon from "../../assets/icons/NoImageIcon";

interface IPropertyCardProps {
  property?: PropertyShort;
}

const PropertyCard: FC<IPropertyCardProps> = ({ property }) => {
  return (
    <div className="w-full relative">
      <div className="absolute top-[10px] right-[10px]">
        <PropertyStatus status="DRAFT" />
      </div>
      {property?.mainImage?.url ? (
        <img
          src={property.mainImage.url}
          alt={property.mainImage.name}
          className="rounded-[10px] w-full aspect-[1.75] object-cover"
        />
      ) : (
        <div className="flex-center aspect-[1.75] bg-[#F8F8F8] rounded-[10px]">
          <NoImageIcon width={40} height={40}/>
        </div>
      )}

      <div className="flex items-center gap-4 mt-[4px] text-[#666] text-[12px]">
        <div className="flex items-center gap-[6px]">
        <EyeIcon />
          <div>1 000</div>
        </div>
        <div className="flex items-center gap-[6px]">
          <PhoneIcon />
          <div>32</div>
        </div>
        <div className="flex items-center gap-[6px]">
          <MessagesIcon/>
          <div>23</div>
        </div>
        <div className="flex items-center gap-[6px]">
          <HeartIcon/>
          <div>24</div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[4px] gap-[12px]">
        <div className="flex items-center flex-1 min-w-0">
          <div className="w-[18px]">
            <LocationIcon />
          </div>
          {/*todo truncate fix*/}
          <div className="ml-[6px] w-auto truncate text-[14px] text-[#333]">
            {getAddressString(property?.address)}
          </div>
        </div>
        <div>
          <div className="rounded-[4px] px-[5px] py-[7px] bg-[#006CFB0D] text-[16px] font-semibold leading-[1]">
            {property?.price && `Kč ${property?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}
            <span className="text-[12px]">{property?.priceType === 'PER_MONTH' ? '/mo' : property?.priceType === 'PER_DAY' ? '/day' : ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;
