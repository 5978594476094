export const PROPERTY_STATUS_LIST = ["ACTIVE", "DRAFT", "IN_MODERATE", "REJECTED", "ARCHIVE"];

const convertName = (value: string) => {
  let returnValue = value;
  returnValue = returnValue.replaceAll("_", " ").toLowerCase();
  returnValue = returnValue.charAt(0).toUpperCase() + returnValue.slice(1);
  return returnValue
}

export const PROPERTY_STATUS_SELECT = PROPERTY_STATUS_LIST.reduce((acc, item) => ([...acc, {
  label: convertName(item),
  value: item,
}]), [] as {label: string; value: string}[]);
