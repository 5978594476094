import axiosInstance from "../../axios";
import {AxiosResponse} from "axios";
import {
  IPostAuthEmailConfirmPayload,
  IPostAuthLoginPayload,
  IPostAuthLoginResponse,
  IPostAuthPasswordResetPayload,
  IPostAuthRegisterAgentPayload
} from "./types";
import {IResponse} from "../../../common/types/response";

export const postAuthLogin = async (payload: IPostAuthLoginPayload) => {
  const response: AxiosResponse<IResponse<IPostAuthLoginResponse>> = await axiosInstance.post("/auth/login", payload);
  return response.data.data;
}

export const postAuthAgentRegister = async (payload: IPostAuthRegisterAgentPayload) => {
  const response: AxiosResponse<string> = await axiosInstance.post("/auth/agent/register", payload);
  return response.data;
}

export const postAuthEmailConfirm = async (payload: IPostAuthEmailConfirmPayload) => {
  const response: AxiosResponse<IResponse<{ accessToken: string }>> = await axiosInstance.post("/auth/email-confirm", payload);
  return response.data.data;
}


export const postAuthPasswordReset = async (payload: IPostAuthPasswordResetPayload): Promise<string> => {
  const response: AxiosResponse<string> = await axiosInstance.post("/auth/password-reset", payload);
  return response.data;
}
