import { useRef, useCallback } from 'react';
import io, { Socket } from 'socket.io-client';

import { IMessage } from "../types/chat";

import useAuthStore from "../../../common/stores/useAuthStore";

import useWebsocketChatStore from "../stores/useWebsocketChatStore";

interface UseWebSocketChatReturn {
  connect: () => void;
  sendMessage: () => void;
  handleMessageRead: (messageId: string) => void;
}

const useWebSocketChat = (): UseWebSocketChatReturn => {
  const socketRef = useRef<Socket | null>(null);

  const { roomId, message, setMessage, messages, setMessages, markMessageRead } = useWebsocketChatStore()
  const { accessToken: token } = useAuthStore((state) => state);

  const connect = useCallback(() => {
    if (!token) return;

    const socket = io('https://api-dev.reelty.cz/chat', { auth: { token } });
    socketRef.current = socket;

    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on('connect', () => {
      console.log('Connected');
      socket.emit('auth');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected');
    });

    socket.on('error', (error: IMessage) => {
      console.error('Error:', error);
      setMessages([error]);
    });

    socket.on('message', (payload: IMessage) => {
      console.log('[ON_MESSAGE]:', payload);
      setMessages([payload]);
    });

    socket.on('readMessages', (payload: { read: { messageId: string }[] }) => {
      console.log('[READ_MESSAGES]:', payload);
      // const readMessagesText = `Messages read: ${payload.read.map((o) => o.messageId).join(', ')}`;
      // setMessages([...messages, { id: 'read', body: readMessagesText }]);
      setMessages(
        messages.map((msg) =>
          payload.read.some((readMsg) => readMsg.messageId === msg.id)
            ? { ...msg, read: true }
            : msg
        )
      );
    });
  }, [token, messages, setMessages]);

  const sendMessage = useCallback(() => {
    if (socketRef.current && message) {
      socketRef.current.emit('message', { roomId, body: message });
      setMessage('');
    }
  }, [message, roomId, setMessage]);

  const handleMessageRead = useCallback((messageId: string) => {
    socketRef.current?.emit('readMessages', { messageIds: [messageId] });
    markMessageRead(messageId);
  }, []);

  return {
    connect,
    sendMessage,
    handleMessageRead,
  };
};

export default useWebSocketChat;
