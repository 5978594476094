import React from "react";

import { UserRole } from "../../../common/types/dto/user";

import useAuthStore from "../../../common/stores/useAuthStore";

import MenuItems from "./MenuItems";
import MenuDocumentationBlock from "./MenuDocumentationBlock";

import Logo from "../../../common/assets/images/Logo";

const Menu = () => {
  const user = useAuthStore((state) => state.user);

  return (
    <div className="h-full w-full pl-[20px] pr-[42px] py-[24px]">
      <div className="flex-center">
        <Logo width={140} height={49}/>
      </div>
      <div className="mt-[40px]">
        <MenuItems/>
      </div>
      {user?.role === UserRole.Agent && (
        <div className="mt-[28px] text-white">
          <MenuDocumentationBlock />
        </div>
      )}
    </div>
  );
}

export default Menu;
