import Logo from "../../common/assets/images/Logo";
import {Link, useNavigate} from "react-router-dom";
import CreateAccountBaseForm from "../../features/create-account/components/CreateAccountBaseForm";
import {useEffect, useMemo} from "react";
import CreateAccountPinForm from "../../features/create-account/components/CreateAccountPinForm";
import useCreateAccountStore from "../../features/create-account/stores/useCreateAccountStore";
import ChevronIcon from "../../common/assets/icons/ChevronIcon";

const CreateAccountPage = () => {
  const navigate = useNavigate();

  const {step, setStep, data: {base: {email}}, resetStore} = useCreateAccountStore((state) => ({...state}));

  //todo make component
  const stepHeader = useMemo(() => {
    switch (step) {
      case "base":
        return "Create Account";
      case "pin":
        return "Verify Your Code";
    }
  }, [step]);

  //todo make component
  const stepDescription = useMemo(() => {
    switch (step) {
      case "base":
        return "Join Reelty and start managing your real estate properties with ease. Please provide your personal information to create your agent account. Your name, email and phone number will be available to users for contact purposes.";
      case "pin":
        return `Please enter the code sent to your email “${email}”. If you didn’t receive the code, click “Resend code”.`;
    }
  }, [step]);

  const handleBackClick = () => {
    switch (step) {
      case "base":
        navigate("/");
        break;
      case "pin":
        setStep("base");
        break;
    }
  }

  useEffect(() => {
    resetStore()
  }, [])

  return (
    <div className="flex flex-col items-center pt-[35px] relative overflow-y-auto h-full pb-[26px] px-[85px]">
      <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
        <div className="flex items-center gap-1">
          <ChevronIcon/>
          <div>Back</div>
        </div>
      </div>
      <div className="w-[140px] h-[49px]">
        <Link to="/">
          <Logo width={140} height={49} />
        </Link>
      </div>
      <div className="flex flex-col items-center pt-[35px]">
        <h2 className="text-2xl text-[#333333] font-bold">{stepHeader}</h2>
        <div className="text-[15px] text-[#666] text-center leading-[140%] mt-4">{stepDescription}</div>
      </div>
      <div className="mt-[50px]">
        {step === "base" && <CreateAccountBaseForm/>}
        {step === "pin" && <CreateAccountPinForm/>}
      </div>
    </div>
  );
}

export default CreateAccountPage;
