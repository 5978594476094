
import DashboardPropertiesBlock from "../../features/dashboard/components/DashboardPropertiesBlock";
import DashboardTotalRevenueBlock from "../../features/dashboard/components/DashboardTotalRevenueBlock";
import DashboardOverviewBlock from "../../features/dashboard/components/DashboardOverviewBlock";
import DashboardLastAddedBlock from "../../features/dashboard/components/DashboardLastAddedBlock";


const DashboardPage = () => {

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <h1 className="text-[#11142d] text-[18px] font-semibold">Dashboard</h1>
      <div className="mt-[20px]">
        <DashboardPropertiesBlock />
      </div>
      <div className="mt-[20px]">
        <DashboardTotalRevenueBlock />
      </div>
      <div className="mt-[20px]">
        <DashboardOverviewBlock />
      </div>
      <div className="mt-[35px]">
        <DashboardLastAddedBlock />
      </div>
    </div>
  )
}

export default DashboardPage;
