import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {postAuthEmailConfirm} from "../../../api/services/auth/requests";
import useCreateAccountStore from "../stores/useCreateAccountStore";
import {useNavigate} from "react-router-dom";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useAuthStore from "../../../common/stores/useAuthStore";

const useFormCreateAccountPin = () => {
  const navigate = useNavigate();
  const {setAccessToken} = useAuthStore((state) => state);
  const {email, resetStore} = useCreateAccountStore((state) => ({
    email: state.data.base.email,
    resetStore: state.resetStore,
  }));

  const {setLoader} = useGlobalLoaderStore();

  const {
    control,
    handleSubmit,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      pin: ""
    }
  });

  const pin = watch("pin");

  const onSubmit = async ({pin}: { pin: string }) => {
    try {
      setLoader(true);
      const {accessToken} = await postAuthEmailConfirm({otp: pin, email});
      setAccessToken(accessToken);
      resetStore()
      setTimeout(() => {
        navigate("/");
      }, 200)
    }
    catch (error: any) {
      const message = error?.response?.data?.message || "Request failed";
      setError("pin", {message})
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (pin.length === 4) handleSubmit(onSubmit)();
  }, [pin]);

  return {
    control,
  }
}

export default useFormCreateAccountPin;
