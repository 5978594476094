import React from "react";
import {useLocation} from "react-router-dom";
import MenuItem from "./MenuItem";
import useMenuItems from "../hooks/useMenuItems";


const Menu = () => {
  let {pathname} = useLocation();
  const {menuItems} = useMenuItems();
  return (
    <div className="flex flex-col gap-[20px] font-Manrope">
      {menuItems.map((item) => (
        <MenuItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          href={item.href}
          selected={item.href === pathname}
        />
      ))}
    </div>
  );
}

export default Menu;
