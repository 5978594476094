import {Link, useNavigate} from "react-router-dom";
import useForgotPasswordStore from "../../features/forgot-password/stores/useForgotPasswordStore";
import Logo from "../../common/assets/images/Logo";
import {useMemo} from "react";
import ForgotPasswordEmailForm from "../../features/forgot-password/components/ForgotPasswordEmailForm";
import ForgotPasswordPinForm from "../../features/forgot-password/components/ForgotPasswordPinForm";
import ForgotPasswordNewPasswordForm from "../../features/forgot-password/components/ForgotPasswordNewPasswordForm";
import ChevronIcon from "../../common/assets/icons/ChevronIcon";
import Success from "../../common/assets/images/Success";
import Button from "../../common/components/Button/Button";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const {step, setStep} = useForgotPasswordStore((state) => ({...state}));

  //todo make component
  const stepNumber = useMemo(() => {
    switch (step) {
      case "email":
        return 1;
      case "pin":
        return 2;
      case "new-password":
        return 3;
    }
  }, [step]);

  //todo make component
  const stepHeader = useMemo(() => {
    switch (step) {
      case "email":
        return "Reset Your Password";
      case "pin":
        return "Verify Your Code";
      case "new-password":
        return "Create a New Password";
    }
  }, [step]);

  //todo make component
  const stepDescription = useMemo(() => {
    switch (step) {
      case "pin":
        return "Please enter the code sent to your email address to reset your password. If you didn’t receive the code, click “Resend code” after the timer expires.";
      case "new-password":
        return "Enter a new password for your account. Please make sure it meets the security requirements and confirm it by entering it again. Click “Reset Password” to save your new password and regain access to your account.";
      default:
        return "Forgot your password? No worries! Enter your email address below, and we’ll send you a code to reset your password.";
    }
  }, [step]);

  const handleBackClick = () => {
    switch (step) {
      case "email":
        navigate("/");
        break;
      case "pin":
        setStep("email");
        break;
      case "new-password":
        setStep("pin");
        break;
    }
  }

  return (
    <div className="flex flex-col items-center pt-[50px] relative overflow-y-auto h-full pb-[50px]">
      {step !== "success" && (
        <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
          <div className="fixed">
            <div className="flex items-center gap-1">
              <ChevronIcon/>
              <div>Back</div>
            </div>
          </div>
        </div>
      )}
      <div className="w-[160px] h-[56px]">
        <Link to="/">
          <Logo/>
        </Link>
      </div>
      {step !== "success" && (
        <>
          <div className="flex flex-col items-center pt-[54px]">
            <div className="font-bold text-[#AAAAAA80]">{stepNumber} / 3</div>
            <h2 className="text-2xl font-bold mt-0.5">{stepHeader}</h2>
            <div className="text-[15px] text-[#666] text-center leading-[140%] mt-4 w-[508px]">{stepDescription}</div>
          </div>
          <div className="mt-[30px]">
            {step === "email" && <ForgotPasswordEmailForm/>}
            {step === "pin" && <ForgotPasswordPinForm/>}
            {step === "new-password" && <ForgotPasswordNewPasswordForm/>}
          </div>
        </>
      )}
      {step === "success" && (
        <div className="flex flex-col items-center flex-1">
          <div className="mt-[64px]">
            <Success/>
          </div>
          <div className="text-[32px] text-[#26203b] mt-[32px]">Password Reset Successfully!</div>
          <div className="text-[20px] text-[#9c9aa5] mt-[10px] w-[662px] text-center">
            Your password has been reset successfully! You can now log in with your new password and continue using
            Reelty.
          </div>
          <div className="w-[352px] mt-auto">
            <Link to="/">
              <Button size="large" fullWidth={true}>
                Back to Sign In
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default ForgotPasswordPage;
