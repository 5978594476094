const Success = () => {

  return (
    <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1000003446">
        <path id="Star 1"
              d="M80.7808 7.96925C86.075 3.39282 93.925 3.39282 99.2192 7.96925C102.975 11.2159 108.162 12.2477 112.874 10.6854C119.517 8.48338 126.769 11.4874 129.909 17.7415C132.137 22.1784 136.534 25.1165 141.486 25.4766C148.465 25.9841 154.016 31.5348 154.523 38.5144C154.884 43.466 157.822 47.8632 162.258 50.0908C168.513 53.2307 171.517 60.4831 169.315 67.1256C167.752 71.8381 168.784 77.0249 172.031 80.7808C176.607 86.075 176.607 93.925 172.031 99.2192C168.784 102.975 167.752 108.162 169.315 112.874C171.517 119.517 168.513 126.769 162.258 129.909C157.822 132.137 154.884 136.534 154.523 141.486C154.016 148.465 148.465 154.016 141.486 154.523C136.534 154.884 132.137 157.822 129.909 162.258C126.769 168.513 119.517 171.517 112.874 169.315C108.162 167.752 102.975 168.784 99.2192 172.031C93.925 176.607 86.075 176.607 80.7808 172.031C77.0249 168.784 71.8381 167.752 67.1256 169.315C60.4831 171.517 53.2307 168.513 50.0908 162.258C47.8632 157.822 43.466 154.884 38.5144 154.523C31.5348 154.016 25.9841 148.465 25.4766 141.486C25.1165 136.534 22.1784 132.137 17.7415 129.909C11.4874 126.769 8.48338 119.517 10.6854 112.874C12.2477 108.162 11.2159 102.975 7.96925 99.2192C3.39282 93.925 3.39282 86.075 7.96925 80.7808C11.2159 77.0249 12.2477 71.8381 10.6854 67.1256C8.48338 60.4831 11.4874 53.2307 17.7415 50.0908C22.1784 47.8632 25.1165 43.466 25.4766 38.5145C25.9841 31.5348 31.5348 25.9841 38.5145 25.4766C43.466 25.1165 47.8632 22.1784 50.0908 17.7415C53.2307 11.4874 60.4831 8.48338 67.1256 10.6854C71.8381 12.2477 77.0249 11.2159 80.7808 7.96925Z"
              fill="#006CFB"/>
        <g id="Ellipse 1" filter="url(#filter0_d_675_12677)">
          <circle cx="90" cy="90" r="64.6734" fill="#ECF0FF"/>
        </g>
        <path id="Stroke-3" d="M69.6484 90.4524L83.2201 104.02L110.352 76.8845" stroke="#006CFB" strokeWidth="5.42714"
              strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <filter id="filter0_d_675_12677" x="21.7086" y="25.3267" width="136.583" height="136.583"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="3.61809"/>
          <feGaussianBlur stdDeviation="1.80905"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_675_12677"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_675_12677" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default Success;
