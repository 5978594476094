import React, { useEffect, useState } from 'react';

import { CategoryFull } from "../../types/dto/category";

import ChevronIcon from "../../assets/icons/ChevronIcon";

interface TreeSelectAccordionProps {
  data: CategoryFull[];
  onSelected?: (state: TreeNodeState) => void;
}

interface TreeNodeState {
  [key: string]: {
    checked: boolean;
    expanded: boolean;
  };
}

const setChildrenCheckedState = (node: CategoryFull, checked: boolean, updatedState: TreeNodeState) => {
  updatedState[node.id] = {
    ...updatedState[node.id],
    checked,
  };

  if (node.children) {
    node.children.forEach(child => setChildrenCheckedState(child, checked, updatedState));
  }
};

const setParentCheckedState = (nodeId: string, data: CategoryFull[], checked: boolean, updatedState: TreeNodeState) => {
  const recurse = (parentNodeId: string, nodes: CategoryFull[]) => {
    for (const node of nodes) {
      if (node.children?.some(child => child.id === parentNodeId)) {
        const hasCheckedChildren = node.children.some(child => updatedState[child.id]?.checked);

        updatedState[node.id] = {
          ...updatedState[node.id],
          checked: hasCheckedChildren,
        };

        recurse(node.id, data);

      } else if (node.children) {
        recurse(parentNodeId, node.children);
      }
    }
  };

  recurse(nodeId, data);
};

const TreeSelectAccordion: React.FC<TreeSelectAccordionProps> = ({ data, onSelected }) => {
  const initialTreeState = data.reduce((acc, node) => {
    const recurse = (node: CategoryFull) => {
      acc[node.id] = {
        checked: false,
        expanded: true,
      };
      node.children?.forEach(recurse);
    };
    recurse(node);
    return acc;
  }, {} as TreeNodeState);

  const [treeState, setTreeState] = useState<TreeNodeState>(initialTreeState);

  const handleCheckboxChange = (node: CategoryFull) => {
    const isChecked = treeState[node.id]?.checked || false;
    const updatedState: TreeNodeState = { ...treeState };

    setChildrenCheckedState(node, !isChecked, updatedState);

    setParentCheckedState(node.id, data, !isChecked, updatedState);

    setTreeState(updatedState);
  };

  const handleExpandCollapse = (node: CategoryFull) => {
    setTreeState(prevState => ({
      ...prevState,
      [node.id]: {
        ...prevState[node.id],
        expanded: !prevState[node.id]?.expanded,
      },
    }));
  };

  const renderTree = (nodes: CategoryFull[]) => {
    return (
      <ul>
        {nodes.map(node => (
          <li key={node.id}>
            <div className="grid grid-cols-[auto_auto_1fr] gap-2 items-center">
              {node.children && node.children.length > 0
                ? (
                  <button  onClick={() => handleExpandCollapse(node)}>
                    <ChevronIcon color={'#333'} className={`w-5 h-5 ${treeState[node.id]?.expanded ? 'transform -rotate-90' : 'transform rotate-180'}`} />
                  </button>
                )
                : <div />
              }
              <input
                type="checkbox"
                className="appearance-none w-5 h-5 border border-blue bg-transparent shrink-0 checked:bg-blue"
                checked={treeState[node.id]?.checked || false}
                onChange={() => handleCheckboxChange(node)}
              />
              {node.title}
            </div>
            <div className="ml-10">
              {node.children && treeState[node.id]?.expanded && renderTree(node.children)}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (onSelected) {
      onSelected(treeState)
    }
  },[treeState])

  if (!data) return <></>

  return <div>{renderTree(data)}</div>;
};

export default TreeSelectAccordion;