import React from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { getEditPropertyById } from "../../api/services/property/requests";
import { getCategorySchemaById } from "../../api/services/category/requests";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import PageHeader from "../../common/components/PageHeader/PageHeader";

import EditIcon from "../../common/assets/icons/EditIcon";

import PropertyStatus from "../../features/property/components/PropertyStatus/PropertyStatus";
import PropertyPreview from "../../features/property/components/PropertyPreview";

import { getSubpageName } from "../../features/property/helpers/propertyName";

const PropertyPage = () => {
  let { id } = useParams();
  const setLoader = useGlobalLoaderStore((state) => state.setLoader);
  const { setError } = useGlobalErrorStore();

  const { data } = useQuery(["getEditPropertyById", "getCategorySchemaById", id], async () => {
    setLoader(true);
    const property = await getEditPropertyById(id!);
    const categorySchema = await getCategorySchemaById(property.category.id);
    setLoader(false);
    return {
      property,
      categorySchema,
    };
  }, {
    enabled: !!id,
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const property = data?.property;
  const categorySchema = data?.categorySchema;
  const subpageName = getSubpageName(property);

  if (!property) return null;

  return (
    <div className="p-[20px_18px_16px_40px] min-h-full">
      <PageHeader
        backButton="/properties"
        start={
          <>
            <div>{subpageName}</div>
            <PropertyStatus status={property.status}/>
          </>
        }
        end={
          <Link to={`/property/${id}/edit`}>
            <div className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center">
              <EditIcon/>
            </div>
          </Link>
        }
      />
      <CardWrapper className="mt-[12px] !p-[20px]">
        <PropertyPreview
          property={property}
          categorySchema={categorySchema}
        />
      </CardWrapper>
    </div>
  );
}

export default PropertyPage;
