import React, { useState, useRef } from 'react';

interface SliderInterval {
  min: number;
  max: number;
  step: number;
}

interface DragSliderProps {
  value?: number;
  onChange?: (value: number) => void;
  defaultValue?: number;
  marks?: number[];
  intervals?: SliderInterval[];
}

const Slider: React.FC<DragSliderProps> = ({
                                             value,
                                             onChange,
                                             defaultValue = 0,
                                             marks = [0, 5, 10, 20, 40, 60, 80, 100, 200, 300, 400, 500],
                                             intervals= [
                                               { min: 0, max: 5, step: 1 },
                                               { min: 5, max: 10, step: 1 },
                                               { min: 10, max: 20, step: 2 },
                                               { min: 20, max: 40, step: 4 },
                                               { min: 40, max: 60, step: 4 },
                                               { min: 60, max: 80, step: 4 },
                                               { min: 80, max: 100, step: 4 },
                                               { min: 100, max: 200, step: 20 },
                                               { min: 200, max: 300, step: 20 },
                                               { min: 300, max: 400, step: 20 },
                                               { min: 400, max: 500, step: 20 },
                                             ],
}) => {
  const [innerValue, setInnerValue] = useState(defaultValue);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const isControlled = typeof value !== "undefined";

  const actualValue = isControlled ? value : innerValue;
  const setActualValue = isControlled && onChange ? onChange : setInnerValue;

  const totalSteps = intervals.length * 5;

  const getValueFromStep = (stepIndex: number) => {
    let cumulativeSteps = 0;

    for (const interval of intervals) {
      const stepsInInterval = 5;

      if (stepIndex < cumulativeSteps + stepsInInterval) {
        const stepInInterval = stepIndex - cumulativeSteps;

        return interval.min + stepInInterval * interval.step;
      }

      cumulativeSteps += stepsInInterval;
    }

    return marks[marks.length - 1];
  };

  const getStepFromPosition = (position: number, width: number) => {
    const relativePosition = position / width;
    return Math.floor(relativePosition * totalSteps);
  };

  const getPositionFromValue = (val: number) => {
    let cumulativeSteps = 0;
    let totalStepIndex = 0;

    for (const interval of intervals) {
      const stepsInInterval = 5;

      if (val >= interval.min && val <= interval.max) {
        totalStepIndex =
          cumulativeSteps +
          Math.round((val - interval.min) / interval.step);
        break;
      }

      cumulativeSteps += stepsInInterval;
    }

    return (totalStepIndex / totalSteps) * 100;
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();

    const slider = sliderRef.current;
    if (slider) {
      const sliderRect = slider.getBoundingClientRect();
      const stepIndex = getStepFromPosition(
        event.clientX - sliderRect.left,
        sliderRect.width
      );
      let newValue = getValueFromStep(stepIndex);
      if (newValue < 0) {
        newValue = 0;
      }
      setActualValue(newValue);
    }

    const handleSelectStart = (selectEvent: Event) => {
      selectEvent.preventDefault();
    };

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (slider) {
        const sliderRect = slider.getBoundingClientRect();
        const stepIndex = getStepFromPosition(
          moveEvent.clientX - sliderRect.left,
          sliderRect.width
        );
        let newValue = getValueFromStep(stepIndex);
        if (newValue < 0) {
          newValue = 0;
        }
        setActualValue(newValue);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('selectstart', handleSelectStart);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('selectstart', handleSelectStart);
  };

  const handleClick = (event: React.MouseEvent) => {
    const slider = sliderRef.current;
    if (slider) {
      const sliderRect = slider.getBoundingClientRect();
      const stepIndex = getStepFromPosition(
        event.clientX - sliderRect.left,
        sliderRect.width
      );
      const newValue = getValueFromStep(stepIndex);
      setActualValue(newValue);
    }
  };

  return (
    <div>
      <div className="relative w-full h-2 bg-[#006CFB14] rounded-[4px] cursor-pointer select-none" onClick={handleClick} ref={sliderRef}>
        <div
          className="absolute h-full bg-[#006CFB] rounded-[4px] select-none"
          style={{ width: `${getPositionFromValue(actualValue)}%` }}
        />
        <div
          className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5 h-5 bg-[#ffffff] border-[5px] border-[#006CFB] rounded-full cursor-grab select-none"
          style={{ left: `${getPositionFromValue(actualValue)}%` }}
          onMouseDown={handleMouseDown}
        />
      </div>
      <div className="flex justify-between mt-4 select-none">
        {marks.map((mark, index) => (
          <div key={index} className="relative w-0 text-center select-none">
            <div className="w-[2px] h-4 bg-[#A0AFC4] mb-1 ml-[50%] transform -translate-x-1/2" />
            <span className="inline-block text-[12px] text-[#A0AFC4] transform -translate-x-1/2">{mark}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
