import React, { useState } from 'react';

import Button from "../../../../common/components/Button/Button";
import { InputUncontrolled } from "../../../../common/components/Input/Input";

interface BillingModalFormProps {
  fullPrice: number;
}

const BillingModalForm: React.FC<BillingModalFormProps> = ({ fullPrice }) => {
  const [promoCode, setPromoCode] = useState('')

  const handleChange = (e: any) => {
    setPromoCode(e.target.value)
  }

  return (
    <div className="grid gap-5 w-[572px]">
      <div>
        <div className="text-[#333] text-[18px] leading-5 font-bold mb-[10px]">Buy Publikace</div>
        <div className="text-[14px]">Jedna Publikace představuje zveřejnění inzerátu na dobu 1 měsíce, bez ohledu na kategorii inzerátu.</div>
      </div>
      <div className="grid grid-cols-[auto] justify-end gap-1 text-right text-[#A0AFC4]">
        <div>Cost per 1 - <span>25</span> Kč</div>
        <div>VAT - <span>5</span> Kč</div>
      </div>
      <div className="grid grid-cols-[auto] justify-end gap-1 text-right text-[#A0AFC4]">
        <div>Můžete použít propagační kód</div>
        <div className="flex items-center gap-[10px]">
          <InputUncontrolled
            onChange={handleChange}
            value={promoCode}
            name="promoCode"
            className="appearance-none w-[150px] h-[35px] text-[14px] border border-[#006CFB] rounded-[4px] text-center outline-none"
            placeholder="XXXXXXX"
          />
          <Button className="flex-center w-[86px] h-[29px] rounded-[8px] text-[12px] p-0">Apply</Button>
        </div>
      </div>
      <div className="flex justify-end items-center gap-[30px]">
        <div className="text-[24px] text-[#006CFB] font-medium">{fullPrice} Kč</div>
        <Button className="flex-center w-[249px] h-[48px] rounded-[8px] text-[16px] font-bold p-0" type="submit">Go to Pay</Button>
      </div>
    </div>
  );
};

export default BillingModalForm;
