import {Outlet} from 'react-router-dom';
import React from "react";
import Menu from "../../features/menu/components/Menu";
import Header from "../../features/header/components/Header";


const BaseLayout = () => {
  return (
    <div className="h-dvh grid grid-cols-[250px_auto] grid-rows-[80px_auto]">
      <div className="row-span-2">
        <Menu />
      </div>
      <div>
        <Header/>
      </div>
      <main className="bg-[#f7f7f7] overflow-auto font-Gilroy">
        <Outlet/>
      </main>
    </div>
  );
}

export default BaseLayout;
