import useFormForgotPasswordNewPassword from "../hooks/useFormForgotPasswordNewPassword";
import Input from "../../../common/components/Input/Input";
import PasswordStrength from "../../create-account/components/PasswordStrength";
import Button from "../../../common/components/Button/Button";
import {useState} from "react";
import useForgotPasswordStore from "../stores/useForgotPasswordStore";

const ForgotPasswordNewPasswordForm = () => {
  const [validPassword, setValidPassword] = useState(false);

  const {
    control,
    isValid,
    password,
    handleSubmit,
  } = useFormForgotPasswordNewPassword();

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-[318px] flex flex-col gap-6">
        <Input
          type="password"
          label="Password"
          name="password"
          control={control}
          placeholder="Enter your new password"
        />
        <PasswordStrength
          password={password}
          setValidPassword={setValidPassword}
        />
        <Input
          type="password"
          label="Confirm password"
          name="confirmPassword"
          control={control}
          placeholder="Enter password again"
        />
      </div>
      <div className="flex-center mt-[46px]">
        <Button
          type="submit"
          size="large"
          disabled={!isValid || !validPassword}
          fullWidth={true}
        >
          Reset password
        </Button>
      </div>
    </form>);
}

export default ForgotPasswordNewPasswordForm;
