import useFormForgotPasswordPin from "../hooks/useFormForgotPasswordPin";
import PinInput from "../../../common/components/PinInput/PinInput";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {postOtpPasswordResetSend} from "../../../api/services/otp/requests";
import useForgotPasswordStore from "../stores/useForgotPasswordStore";

const ForgotPasswordPinForm = () => {
  const {email} = useForgotPasswordStore((state) => state);
  const {control} = useFormForgotPasswordPin();
  const [counter, setCounter] = useState(60);

  const startCounter = () => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1)
    }, 1000);
  }

  useEffect(() => {
    startCounter();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1)
    }, 1000);
  }, []);


  const resendCode = async () => {
    if(counter > 0) return;
    setCounter(60);
    try {
      await postOtpPasswordResetSend({email});
      startCounter();
    }
    catch (error){
      alert(error)
    }
  };


  return (
    <div>
      <PinInput
        length={4}
        defaultValue={""}
        name="pin"
        control={control}
      />
      <div className={classNames(
        "text-[15px] text-[#666] text-center leading-[140%] mt-[32px]", {
          "cursor-pointer": counter <= 0,
        })} onClick={resendCode}>
        Resend code {counter >= 0 && counter}
      </div>
    </div>
  );
}

export default ForgotPasswordPinForm;
