import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const CheckIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color || "#465FF1"}
      strokeOpacity={opacity || 1}
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
}

export default CheckIcon;
