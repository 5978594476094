import {Control, Controller} from "react-hook-form";
import {FC, useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {InputUncontrolled} from "../Input/Input";

interface ICalendarProps {
  control: Control;
  name: string;
}

const Calendar: FC<ICalendarProps> = ({
                                        control,
                                        name,
                                      }) => {
  const inputRef = useRef(null);
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <DatePicker
          customInput={<InputUncontrolled />}
          selected={field.value}
          onChange={(date) => field.onChange(date)}
        />
      )} />
  )
}

export default Calendar;
