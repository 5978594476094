import * as zod from "zod"

export const createAccountBaseFormSchema = zod.object({
  name: zod.string().min(1),
  email: zod.string().email("Enter valid email"),
  password: zod.string().min(8),
  confirmPassword: zod.string().min(8),
  phone: zod.string().min(1),
  legalName: zod.string().min(1),
  address: zod.string().min(1),
  ic: zod.string().min(1),
  companyPhone: zod.string().min(1),
}).refine(
  (values) => {
    return values.password === values.confirmPassword;
  },
  {
    message: "Passwords must match!",
    path: ["confirmPassword"],
  }
);
