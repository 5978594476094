const NotFoundPage = () => {

  return (
    <div className="w-full h-full flex-center flex-col">
      <div className="font-bold text-[40px]">404</div>
      <div className="text-[22px]">Page Not Found</div>
    </div>
  )
}

export default NotFoundPage;
