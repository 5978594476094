import {useForm} from "react-hook-form";
import {IForgotPasswordPin} from "../types/form";
import useForgotPasswordStore from "../stores/useForgotPasswordStore";
import {useEffect} from "react";
import {zodResolver} from "@hookform/resolvers/zod";
import {forgotPasswordPinFormSchema} from "../validations/schema";
import {postOtpPasswordResetCheck} from "../../../api/services/otp/requests";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

const useFormForgotPasswordPin = () => {
  const {setStep, email, setPin} = useForgotPasswordStore((state) => state);
  const {setLoader} = useGlobalLoaderStore((state) => state);

  const {
    control,
    handleSubmit,
    watch,
    setError
  } = useForm<IForgotPasswordPin>({
    mode: "onBlur",
    defaultValues: {
      pin: ""
    },
    resolver: zodResolver(forgotPasswordPinFormSchema),
  });

  const pin = watch("pin");

  const onSubmit = async ({pin}: IForgotPasswordPin) => {
    try {
      setLoader(true);
      const {data} = await postOtpPasswordResetCheck({
        otp: pin,
        email,
      });

      if(data.valid){
        setPin(pin);
        setStep("new-password");
      }
      else {
        setError("pin", {message: "Pin is not valid"})
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || "Request failed";
      setError("pin", {message})
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (pin.length === 4) handleSubmit(onSubmit)();
  }, [pin]);


  return {
    control,
    handleSubmit: handleSubmit(onSubmit),

  }
}

export default useFormForgotPasswordPin;
