import React, { useMemo, useState } from "react";

import { PropertyAddress } from "../../../common/types/dto/property";

import useWebsocketChatStore from "../stores/useWebsocketChatStore";

import { InputUncontrolled } from "../../../common/components/Input/Input";

import ChatListButton from "./ChatListButton";

import MagnifyingGlass from "../../../common/assets/icons/MagnifyingGlass";

interface ChatListItem {
  id: string;
  name: string;
  avatar: string | null;
  address?: PropertyAddress;
  unreadMessages: number;
  isSupport: boolean;
}

interface ChatsListProps {
  chatListData: ChatListItem[];
}

const ChatsList: React.FC<ChatsListProps> = ({ chatListData }) => {
  const [searchFilter, setSearchFilter] = useState('')

  const { roomId, setRoomId } = useWebsocketChatStore()

  const buttonsMap = useMemo(() => chatListData
    .filter(({ name }) => name?.toLowerCase().includes(searchFilter.toLowerCase()))
    .map(({ name, address, avatar, unreadMessages, id, isSupport }) => (
      <ChatListButton key={id} onClick={() => setRoomId(id)} name={name} address={address} avatar={avatar} isActive={id === roomId} unreadMessages={unreadMessages} isSupport={isSupport} />
    )), [chatListData, searchFilter, roomId])

  return (
    <div className="w-[347px] bg-white p-5 rounded-l-[15px] h-[calc(100vh-124px)] min-h-[538px]">
      <div className="relative">
        <InputUncontrolled
          type="text"
          className="flex items-center w-full h-[50px] pl-[50px] pr-[10px] mb-[25px]"
          placeholder="Search..."
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2" />
      </div>
      <div className="grid gap-[24px]">
        {buttonsMap}
      </div>
    </div>
  )
}

export default ChatsList;
