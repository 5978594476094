import ChevronIcon from "../../common/assets/icons/ChevronIcon";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Logo from "../../common/assets/images/Logo";
import Button from "../../common/components/Button/Button";
import {useMemo, useState} from "react";
import {VerifyEmailPageStepType} from "../../features/verify-email/types/step";
import VerifyEmailPinForm from "../../features/verify-email/components/VerifyEmailPinForm";
import {postOtpEmailConfirmSend} from "../../api/services/otp/requests";
import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";

const VerifyEmailPage = () => {
  const {setLoader} = useGlobalLoaderStore((state) => state)
  const [step, setStep] = useState<VerifyEmailPageStepType>("send-code");
  const navigate = useNavigate();

  const [searchParams]  = useSearchParams();

  const email = atob(searchParams.get("email") || "");

  //todo make component
  const stepHeader = useMemo(() => {
    switch (step) {
      case "send-code":
        return "Verify Your Email";
      case "pin":
        return "Verify Your Code";
    }
  }, [step]);

  //todo make component
  const stepDescription = useMemo(() => {
    switch (step) {
      case "send-code":
        return `Your email ${email} is not verified. Please verify your email by sending a code to your email address.`;
      case "pin":
        return `Please enter the code sent to your email “${email}”. If you didn’t receive the code, click “Resend code”.`;
    }
  }, [step, email]);

  const handleBackClick = () => {
    switch (step) {
      case "send-code":
        navigate("/");
        break;
      case "pin":
        setStep("send-code");
        break;
    }
  };

  const handleSendCodeClick = async () => {
    try {
      setLoader(true)
      await postOtpEmailConfirmSend({email});
      setStep("pin")
    }
    catch (e){
      console.error(e);
    }
    finally {
      setLoader(false);
    }
  }

  return (
    <div className="flex flex-col items-center pt-[50px] relative overflow-y-auto h-full pb-[50px]">
      <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
        <div className="fixed">
          <div className="flex items-center gap-1">
            <ChevronIcon/>
            <div>Back</div>
          </div>
        </div>
      </div>
      <div className="w-[160px] h-[56px]">
        <Link to="/">
          <Logo/>
        </Link>
      </div>
      <div className="flex flex-col items-center pt-[54px]">
        <h2 className="text-2xl font-bold mt-0.5">{stepHeader}</h2>
        <div className="text-[15px] text-[#666] text-center leading-[140%] mt-4 w-[508px]">
          {stepDescription}
        </div>
      </div>
      {step === "send-code" && (
        <div className="w-[358px] mt-[62px]">
          <Button fullWidth={true} onClick={handleSendCodeClick}>
            Send code
          </Button>
        </div>
      )}
      {step === "pin" && (
        <div className="mt-[62px]">
          <VerifyEmailPinForm email={email}/>
        </div>
      )}
    </div>
  );
}

export default VerifyEmailPage;
